interface TitleProps {
  text: string
}

export const Title = ({ text }: TitleProps) => {
  return (
    <p className="mb-1">
      <span>{text}</span>
    </p>
  )
}
