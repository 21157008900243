import { makeAutoObservable, observable } from 'mobx'
import { Api } from 'api/Api'
import { Toaster } from 'hooks/useToaster'
import { Thread } from 'components/ps-chart/models/Thread'
import { ThreadsRenderSettings } from 'components/ps-chart/models/settings'
import {
  linkTreeNodes,
  mergeTreeNodes,
  TreeNode,
} from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { walkOverTree } from 'components/ps-chart/stores/connections-store/LinksTree/walkOverTree'
import { Slice, SliceRange } from 'components/ps-chart/models/Slice'
import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById, TraceDataState } from 'components/ps-chart/stores/TraceDataStore'
import { ChartPageParams, TraceSettingsDto, NamedLinkDto } from 'api/models'
import { getNonVisibleConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'
import { getMainChainFromTree } from 'components/ps-chart/stores/connections-store/LinksTree/getMainChainFromTree'
import {
  getLinksTree,
  getLinksTreeSimple,
} from 'components/ps-chart/stores/connections-store/LinksTree/getLinksTree'
import {
  findClosestRightIndex,
  findSlice,
  getChildNetworkRequests,
  walkSlices,
} from 'components/ps-chart/utils/slice'
import { removeLinksBySourceId } from 'components/ps-chart/stores/connections-store/createNamedLink'
import { BorderType, LineType } from 'components/ps-chart/connections-render/NodeRenderData'
import { fillNamedLinks } from 'components/ps-chart/stores/connections-store/fillNamedLinks'
import { ConnectionType } from '../models/ConnectionType'

export interface Error {
  id: number
  msg: string
}

interface ConnectionData {
  fromSliceId: number
  toSliceId: number
  connectionType: ConnectionType
  lineType: LineType
}

export interface ChainNodeData {
  slice: Slice
  borderType: BorderType
  thread: Thread
  threadActiveLevels: number[]
}

export interface ChainDelayData {
  start: number
  end: number
}

const CHOREOGRAPHER_PATTERN = 'Choreographer#doFrame'

const IOS_PATH_LOOKUP_PATTERNS = [
  ' viewDidLoad(',
  ' viewDidAppear(',
  ' viewDidLayoutSubviews(',
  ' viewDidDisappear(',
]

/**
 * For Cycle slice/function (Android/iOS) we are searching for execution path which leads into selected "cycle".
 * So for specific Cycle we are searching for Variants (slices/functions) with execution path.
 * Variants are split into two types based on their execution time and ancestor slice.
 *
 * SIBLING Variant: {@link TraceAnalyzeStore.getCycleSliceSiblingVariants}
 * Slices/Functions which are executed between selected cycle and closest previous cycle
 *
 * CHILD Variant: {@link TraceAnalyzeStore.getCycleSliceChildVariants}
 * Slices/Functions which are called by selected Cycle
 */
enum CycleVariantType {
  SIBLING = 'SIBLING',
  CHILD = 'CHILD',
}

/**
 * Array of all cycle variants should contain variant type, so it wouldn't need to re-check which connection type will be used
 * to create link between variant slice and cycle slice in {@link TraceAnalyzeStore.setCycleLink}
 */
interface CycleVariant {
  sliceId: number
  type: CycleVariantType
  chain: Slice[]
  chainThreadIDs: string
  chainLastSliceId: number
}

enum DetailsChainType {
  REGULAR,
  NATIVE,
}

export type MaxLevelByThreadId = Map<number, number>
export type LevelsByThreadId = Map<number, number[]>
export type HeightByThreadId = Map<number, number>
export type TopBottom = [number, number]
export type TopBottomByThreadId = Map<number, TopBottom>

export interface SlicesState {
  sliceById: ReadonlySliceById
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>
  namedLinksByLinkId: Map<string, NamedLinkDto>
  cycleLegacyVariantsBySliceId: Map<number, CycleVariant[]>
  cycleProcessedVariantsBySliceId: Map<number, CycleVariant[]>
}

export interface ThreadsState {
  threads: ReadonlyArray<Thread>
  threadsById: ReadonlyMap<number, Thread>
  mainThreads: Thread[]
  favThreads: Thread[]
  utilThreads: Thread[]
  pinnedThreads: Thread[]
  favIdSet: Set<number>
  utilIdSet: Set<number>
  maxLevelByThreadId: MaxLevelByThreadId
}

export class TraceAnalyzeStore implements ThreadsState, SlicesState {
  /*
   * THREADS
   */
  private readonly traceDataState: TraceDataState
  readonly threadSettings: ThreadsRenderSettings

  favThreads: Thread[] = []

  showMaxLevelMode = false
  showOnlyActiveLevelsMode = false

  deprioritizedThreadIds: Array<number> = []

  private readonly api: Api
  private readonly toaster: Toaster
  private readonly chartPageParams: Required<ChartPageParams>

  /*
   * SLICES & CHAINS
   */
  shouldShowAllPaths = false
  detailsChainType: DetailsChainType = DetailsChainType.REGULAR

  selectedCycleSliceVariantIndex = 0
  cycleLegacyVariantsBySliceId = new Map<number, CycleVariant[]>()
  cycleProcessedVariantsBySliceId = new Map<number, CycleVariant[]>()
  private cycleSelectedVariantIndexLookup = new Map<number, number>()
  private cyclePinnedVariantIndexLookup = new Map<number, number>()
  private cycleLegacyPinnedVariantIndexLookup = new Map<number, number>()

  selectedSlice: Slice | null = null
  sliceLinksBySliceId = new Map<number, ReadonlyArray<SliceLink>>()
  namedLinksByLinkId = new Map<string, NamedLinkDto>()

  isBeConnectionsEnabled = false
  isCycleFiltrationEnabled = false

  setIsBeConnectionsEnabled(value: boolean) {
    this.isBeConnectionsEnabled = value
  }

  setIsCycleFiltrationEnabled(value: boolean) {
    this.isCycleFiltrationEnabled = value
  }

  constructor(
    api: Api,
    toaster: Toaster,
    threadsDataState: TraceDataState,
    threadSettings: ThreadsRenderSettings,
    chartPageParams: Required<ChartPageParams>,
  ) {
    makeAutoObservable<TraceAnalyzeStore, 'api' | 'toaster' | 'chartPageParams'>(this, {
      api: false,
      toaster: false,
      chartPageParams: false,
      threadSettings: false,
      sliceById: false,
      threads: false,
      utilThreads: false,
      threadsById: false,

      sliceLinksBySliceId: observable.shallow,

      cycleLegacyVariantsBySliceId: false,
      cycleProcessedVariantsBySliceId: false,

      favThreads: observable.shallow,
      selectedSlice: observable.ref,
    })

    this.api = api
    this.toaster = toaster
    this.chartPageParams = chartPageParams
    this.threadSettings = threadSettings
    this.traceDataState = threadsDataState
  }

  get utilThreads(): Thread[] {
    return this.traceDataState.utilityThreads
  }

  get pinnedThreads(): Thread[] {
    return [...this.traceDataState.utilityThreads, ...this.favThreads]
  }

  get pinnedIdsSet(): Set<number> {
    return new Set(this.pinnedThreads.map(({ id }) => id))
  }

  get threads(): ReadonlyArray<Thread> {
    return this.traceDataState.threads
  }

  get threadsById(): ReadonlyMap<number, Thread> {
    return this.traceDataState.threadsById
  }

  get mainThreads(): Thread[] {
    let mainThreads = this.calcMainThreads()

    if (this.chainExists && this.showOnlyActiveLevelsMode) {
      mainThreads = mainThreads.filter((thread) => this.activeThreadIdsFromChain.has(thread.id))
    }

    //WHY: No need to check for thread's weight if there is only one active thread
    if (this.activeThreadIdsFromChain.size < 2) {
      return mainThreads
    }

    const weightByThreadId: Map<number, number> = new Map()
    let curWeight = 0
    weightByThreadId.set(this.threads[0].id, curWeight++)
    for (const slice of [...this.mainChainSlices].reverse()) {
      if (!weightByThreadId.has(slice.threadId)) {
        weightByThreadId.set(slice.threadId, curWeight++)
      }
    }

    if (this.shouldShowAllPaths) {
      walkOverTree(this.linksTree, (treeNode) => {
        const slice = this.sliceById.get(treeNode.sliceId)!
        if (!weightByThreadId.has(slice.threadId)) {
          weightByThreadId.set(slice.threadId, curWeight++)
        }
      })
    }

    if (this.mainChainRange !== undefined) {
      const { start: minChainRange, end: maxChainRange } = this.mainChainRange
      for (const thread of this.threads) {
        if (thread.networkRequestsRange.length && !weightByThreadId.has(thread.id)) {
          const { start: minRange } = thread.networkRequestsRange[0]
          const { end: maxRange } =
            thread.networkRequestsRange[thread.networkRequestsRange.length - 1]
          if (minRange > maxChainRange || minChainRange > maxRange) {
            continue
          }
          for (const { start, end } of thread.networkRequestsRange) {
            if (
              (start >= minChainRange && start < maxChainRange) ||
              (end > minChainRange && end <= maxChainRange) ||
              (start < minChainRange && end > maxChainRange)
            ) {
              weightByThreadId.set(thread.id, curWeight++)
            }
          }
        }
      }
    }

    mainThreads.sort((aThread, bThread) => {
      const aWeight = weightByThreadId.get(aThread.id) ?? +Infinity
      const bWeight = weightByThreadId.get(bThread.id) ?? +Infinity
      const diff = aWeight - bWeight
      return isNaN(diff) ? 0 : diff
    })

    return mainThreads
  }

  calcMainThreads(): Thread[] {
    const favSet = new Set(this.favThreads)
    const deprioritizedThreads: Thread[] = []
    const filteredThreads: Thread[] = this.threads.filter((thread) => {
      if (this.deprioritizedThreadIds.includes(thread.id)) {
        deprioritizedThreads.push(thread)
        return false
      }
      return !favSet.has(thread)
    })
    filteredThreads.push(...deprioritizedThreads)
    return filteredThreads
  }

  get utilIdSet(): Set<number> {
    return new Set(this.utilThreads.map((thread) => thread.id))
  }

  get favIdSet(): Set<number> {
    return new Set(this.favThreads.map((thread) => thread.id))
  }

  /**
   * Returns Max level for all the threads based on:
   *
   * With selected slice with execution path {@link chainExists} and active view filtration
   * {@link showMaxLevelMode} === false either {@link showOnlyActiveLevelsMode} === true -
   * max level calculated by getting active levels from {@link activeLevelsFromChainByThreadId}.
   *
   * Otherwise - thread's original max level
   *
   * Previously had one more rule based on showNormalModeFullDepth which was not used in UI
   */
  get maxLevelByThreadId(): MaxLevelByThreadId {
    const maxLevelByThreadId: MaxLevelByThreadId = new Map()

    for (const utilityThread of this.utilThreads) {
      maxLevelByThreadId.set(utilityThread.id, 1)
    }

    const alreadyAdded = new Set<number>()
    if (this.chainExists) {
      const activeLevelsByThreadId = this.activeLevelsFromChainByThreadId
      if (this.showOnlyActiveLevelsMode) {
        activeLevelsByThreadId.forEach((level, threadId) => {
          maxLevelByThreadId.set(threadId, level.indexOf(Math.max(...level)))
          alreadyAdded.add(threadId)
        })
        for (const favoriteThread of this.favThreads) {
          if (!alreadyAdded.has(favoriteThread.id)) {
            maxLevelByThreadId.set(favoriteThread.id, 0)
            alreadyAdded.add(favoriteThread.id)
          }
        }
      } else {
        if (!this.showMaxLevelMode) {
          activeLevelsByThreadId.forEach((level, threadId) => {
            maxLevelByThreadId.set(threadId, Math.max(...level))
            alreadyAdded.add(threadId)
          })
        }
      }
    }

    this.threads.forEach((thread) => {
      if (!alreadyAdded.has(thread.id)) {
        maxLevelByThreadId.set(thread.id, thread.maxLevel)
      }
    })
    return maxLevelByThreadId
  }

  get heightByThreadId(): HeightByThreadId {
    const heightByThreadId: MaxLevelByThreadId = new Map()
    const { threads, utilThreads } = this
    const settings = this.threadSettings
    for (let i = 0; i < threads.length; i++) {
      const thread = threads[i]
      const totalLevel = this.maxLevelByThreadId.get(thread.id)! + 1
      const height = Math.max(
        settings.minHeight,
        totalLevel * settings.blockHeight + settings.bottomPadding + settings.topPadding,
      )
      heightByThreadId.set(thread.id, height)
    }
    for (let i = 0; i < utilThreads.length; i++) {
      const thread = utilThreads[i]
      const totalLevel = this.maxLevelByThreadId.get(thread.id)! + 1
      const height = Math.max(
        settings.minHeight,
        totalLevel * settings.blockHeight + settings.bottomPadding + settings.topPadding,
      )
      heightByThreadId.set(thread.id, height)
    }
    return heightByThreadId
  }

  toggleFavoriteThread(thread: Thread) {
    if (this.favThreads.includes(thread)) {
      this.favThreads = this.favThreads.filter((curThread) => curThread.id !== thread.id)
    } else {
      this.favThreads.push(thread)
    }
  }

  deprioritizeThread(threadId: number) {
    this.deprioritizedThreadIds.push(threadId)
  }

  toggleThreadForceMaxLevelMode() {
    this.showMaxLevelMode = !this.showMaxLevelMode
  }

  toggleThreadShrunkMode() {
    this.showOnlyActiveLevelsMode = !this.showOnlyActiveLevelsMode
  }

  reset() {
    this.sliceLinksBySliceId.clear()
  }

  get sliceById(): ReadonlySliceById {
    return this.traceDataState.sliceById
  }

  setSelectedSlice(slice: Slice | null) {
    this.selectedSlice = slice
    this.selectedCycleSliceVariantIndex = 0

    if (slice !== null && TraceAnalyzeStore.isCycleSlice(slice)) {
      if (
        this.cycleSelectedVariantIndexLookup.has(slice.id) ||
        this.cyclePinnedVariantIndexLookup.has(slice.id)
      ) {
        this.selectedCycleSliceVariantIndex = this.getSelectedCycleSliceVariantIndex()
      } else {
        this.selectCycleSliceVariant(slice.id, 0)
      }
    }
  }

  /**
   * fillNamedLinks does generate tree and other data for execution path instead of "reaction"
   * because tests don't catch reaction on time.
   */
  fillNamedLinks(namedLinks: NamedLinkDto[]) {
    fillNamedLinks(this.sliceById, this.sliceLinksBySliceId, this.namedLinksByLinkId, namedLinks)
  }

  setCycleLink(cycleSliceId: number, cycleVariantIndex: number) {
    const cycleSlice = this.sliceById.get(cycleSliceId)!
    const cycleVariant = this.cyclePathVariants(cycleSlice)[cycleVariantIndex]

    const cycleVariantSlice = this.sliceById.get(cycleVariant?.sliceId)
    if (cycleVariantSlice !== undefined) {
      const connectionType =
        cycleVariant.type === CycleVariantType.CHILD ? ConnectionType.ASYNC : ConnectionType.MANUAL
      const links = Array.from(this.sliceLinksBySliceId.get(cycleSlice.id) ?? [])
      links.push({
        sourceId: `cycle${cycleSlice.id}`,
        fromSliceId: cycleSlice.id,
        toSliceId: cycleVariantSlice.id,
        connectionType,
        isEditable: false,
      })
      this.sliceLinksBySliceId.set(cycleSlice.id, links)
    }
  }

  pinCycleVariant(cycleSliceId: number, cycleVariantIndex: number, isLegacyIndex: boolean) {
    const cycleSlice = this.sliceById.get(cycleSliceId)!
    if (cycleSlice !== undefined) {
      const variantIndex = isLegacyIndex
        ? this.cycleIndexFromLegacyToSorted(cycleSlice, cycleVariantIndex)
        : cycleVariantIndex
      this.cyclePinnedVariantIndexLookup.set(cycleSliceId, variantIndex)
      if (!this.cycleSelectedVariantIndexLookup.has(cycleSliceId)) {
        this.setCycleLink(cycleSliceId, variantIndex)
      }
    } else {
      console.warn(`Pinned slice (id: ${cycleSliceId}) wasn't found`)
    }
  }

  selectCycleSliceVariant(cycleSliceId: number, cycleVariantIndex: number) {
    const pinnedIndex = this.cyclePinnedVariantIndexLookup.get(cycleSliceId) ?? -1
    removeLinksBySourceId(this.sliceLinksBySliceId, `cycle${cycleSliceId}`)
    if (pinnedIndex === cycleVariantIndex) {
      this.cycleSelectedVariantIndexLookup.delete(cycleSliceId)
    } else {
      this.cycleSelectedVariantIndexLookup.set(cycleSliceId, cycleVariantIndex)
    }
    if (!this.isBeConnectionsEnabled) {
      this.setCycleLink(cycleSliceId, cycleVariantIndex)
    }
  }

  pinCycleVariants(cyclePinnedVariantIndexes: TraceSettingsDto) {
    this.cycleLegacyPinnedVariantIndexLookup = new Map<number, number>()
    /**
     * Data from backend can be not sorted chronologically - so we need to sort it
     * before it will be passed through link process
     **/
    const legacyVariantsPairs = cyclePinnedVariantIndexes.choreographerPath.sort(
      (currentPath, previousPath) => currentPath.sliceId - previousPath.sliceId,
    )

    for (const { pathId, sliceId } of legacyVariantsPairs) {
      this.cycleLegacyPinnedVariantIndexLookup.set(sliceId, pathId)
      this.pinCycleVariant(sliceId, pathId, true)
    }
  }

  static isChoreographerSlice(slice: Slice) {
    return slice.level === 0 && slice.title.includes(CHOREOGRAPHER_PATTERN)
  }

  private static isIosPathLookupSlice(slice: Slice) {
    return (
      slice.level === 0 &&
      slice.title.endsWith(')') &&
      IOS_PATH_LOOKUP_PATTERNS.some((x) => slice.title.includes(x))
    )
  }

  private static isFramePathLookupSlice(slice: Slice) {
    const regex = /^F\d+$/
    return slice.level === 0 && regex.test(slice.title)
  }

  static isCycleSlice(slice: Slice) {
    return (
      TraceAnalyzeStore.isChoreographerSlice(slice) ||
      TraceAnalyzeStore.isIosPathLookupSlice(slice) ||
      TraceAnalyzeStore.isFramePathLookupSlice(slice)
    )
  }

  get isChoreographerSliceSelected(): boolean {
    return this.selectedSlice != null && TraceAnalyzeStore.isChoreographerSlice(this.selectedSlice)
  }

  get isFrameSliceSelected(): boolean {
    return (
      this.selectedSlice != null && TraceAnalyzeStore.isFramePathLookupSlice(this.selectedSlice)
    )
  }

  get isCycleSliceSelected(): boolean {
    return this.selectedSlice != null && TraceAnalyzeStore.isCycleSlice(this.selectedSlice)
  }

  getCycleSliceCurrentVariantIndex(slice: Slice) {
    if (TraceAnalyzeStore.isCycleSlice(slice)) {
      return TraceAnalyzeStore.getCycleSliceVariantIndex(
        slice.id,
        this.cycleSelectedVariantIndexLookup,
        this.cyclePinnedVariantIndexLookup,
      )
    }
    return 0
  }

  private getSelectedCycleSliceVariantIndex() {
    if (this.isCycleSliceSelected) {
      return TraceAnalyzeStore.getCycleSliceVariantIndex(
        this.selectedSlice!.id!,
        this.cycleSelectedVariantIndexLookup,
        this.cyclePinnedVariantIndexLookup,
      )
    }
    return 0
  }

  static getCycleSliceVariantIndex(
    sliceId: number,
    cycleSelectedVariantIndexLookup: Map<number, number>,
    cyclePinnedVariantIndexLookup: Map<number, number>,
  ) {
    return (
      cycleSelectedVariantIndexLookup.get(sliceId) ??
      cyclePinnedVariantIndexLookup.get(sliceId) ??
      0
    )
  }

  /**
   * mapVariant will store main execution path of cycle variant without:
   *
   * recurse - which can happen only with {@link CycleVariantType.CHILD} because of {@link ConnectionType.ASYNC}
   * when there is already pinned variant for selected cycle slice will cause path going through same cycle slice again.
   *
   * next Cycle path merging - it's better to sort and filter variants only based on original chain without merging with
   * possible pinned\selected variant of next cycle slice. It can trigger filter to remove original variant path.
   **/
  private mapVariant(
    cycleSliceId: number,
    variantSliceId: number,
    variantType: CycleVariantType,
  ): CycleVariant {
    let chain = this.getMainChainSlicesBySliceId(variantSliceId)
    if (variantType === CycleVariantType.CHILD) {
      const recurseIndex = chain.findIndex((slice) => slice.id === cycleSliceId)
      if (recurseIndex !== -1) {
        chain = chain.slice(0, recurseIndex)
      }
    }

    const nextCycleIndex = chain.findIndex(
      (slice) => slice.id !== cycleSliceId && TraceAnalyzeStore.isCycleSlice(slice),
    )
    if (nextCycleIndex !== -1) {
      chain = chain.slice(0, nextCycleIndex + 1)
    }

    const chainThreadIDs = Array.from(new Set(chain.map((slice) => slice.threadId)))
      .sort((prev, next) => prev - next)
      .join('/')

    const chainLastSliceID = chain[chain.length - 1].id

    return {
      sliceId: variantSliceId,
      type: variantType,
      chain,
      chainThreadIDs,
      chainLastSliceId: chainLastSliceID,
    }
  }

  private getCycleSliceChildVariants = (cycleSlice: Slice): CycleVariant[] => {
    const childrenSet = new Set<number>()
    walkSlices([cycleSlice], (curSlice) => childrenSet.add(curSlice.id))

    const variantSlices = new Set<number>()
    walkSlices([...cycleSlice.children!], (curSlice) => {
      const curLinks = this.sliceLinksBySliceId.get(curSlice.id)
      if (curLinks != null && curLinks.length > 0) {
        for (const curLink of curLinks) {
          if (childrenSet.has(curLink.toSliceId) || variantSlices.has(curLink.fromSliceId)) {
            continue
          }
          const fromSlice = this.sliceById.get(curLink.fromSliceId)!
          const toSlice = this.sliceById.get(curLink.toSliceId)!
          const nonVisibleError = getNonVisibleConnectionError(
            fromSlice,
            toSlice,
            this.sliceById,
            this.sliceLinksBySliceId,
          )
          if (nonVisibleError != null) {
            continue
          }
          variantSlices.add(curLink.fromSliceId)
        }
      }
    })
    return Array.from(variantSlices).map((variantSliceId) =>
      this.mapVariant(cycleSlice.id, variantSliceId, CycleVariantType.CHILD),
    )
  }

  private getCycleSliceSiblingVariants = (cycleSlice: Slice): CycleVariant[] => {
    const variantSlices = new Set<number>()
    const selectedThread = this.threadsById.get(cycleSlice.threadId)!
    for (let posIndex = cycleSlice.rootPositionIndex - 1; posIndex >= 0; posIndex--) {
      const curRootSlice = selectedThread.slices[posIndex]
      if (
        TraceAnalyzeStore.isChoreographerSlice(curRootSlice) ||
        TraceAnalyzeStore.isIosPathLookupSlice(curRootSlice)
      ) {
        break
      }
      const curLinks = this.sliceLinksBySliceId.get(curRootSlice.id)
      if (curLinks != null) {
        for (const curLink of curLinks) {
          if (variantSlices.has(curLink.fromSliceId)) {
            continue
          }

          const fromSlice = this.sliceById.get(curLink.fromSliceId)!
          const toSlice = this.sliceById.get(curLink.toSliceId)!
          const nonVisibleError = getNonVisibleConnectionError(
            fromSlice,
            toSlice,
            this.sliceById,
            this.sliceLinksBySliceId,
          )
          if (nonVisibleError != null) {
            continue
          }

          variantSlices.add(curLink.fromSliceId)
        }
      }
    }
    return Array.from(variantSlices).map((variantSliceId) =>
      this.mapVariant(cycleSlice.id, variantSliceId, CycleVariantType.SIBLING),
    )
  }

  /**
   * Concept idea to search for closest group of executed function before selected frame
   * First we search for closest
   **/
  private getCycleSliceVariantsFromMainThread = (cycleSlice: Slice): CycleVariant[] => {
    const variantSlices = new Set<number>()
    const mainThread = this.traceDataState.mainThread!

    const mainThreadRootDepth = mainThread.slicesByLevel.get(0)!
    const closestRightIndex = findClosestRightIndex(mainThreadRootDepth, cycleSlice.start)
    const threadRangeEndIndex =
      mainThreadRootDepth[closestRightIndex].start > cycleSlice.start
        ? closestRightIndex - 1
        : closestRightIndex

    let threadRangeStartIndex = 0
    for (let searchStartIndex = threadRangeEndIndex; searchStartIndex >= 0; searchStartIndex--) {
      if (searchStartIndex !== 0) {
        const nextSlice = mainThreadRootDepth[searchStartIndex - 1]
        const startSlice = mainThreadRootDepth[searchStartIndex]
        const nextPause = startSlice.start - nextSlice.end
        const approximateFramePause = (cycleSlice.end - cycleSlice.start) * 0.75
        const rangeDuration = mainThreadRootDepth[threadRangeEndIndex].start - startSlice.end
        if (nextPause >= approximateFramePause && rangeDuration >= approximateFramePause) {
          threadRangeStartIndex = searchStartIndex
          break
        }
      }
    }

    for (let posIndex = threadRangeEndIndex; posIndex >= threadRangeStartIndex; posIndex--) {
      const curRootSlice = mainThread.slices[posIndex]
      if (
        TraceAnalyzeStore.isChoreographerSlice(curRootSlice) ||
        TraceAnalyzeStore.isIosPathLookupSlice(curRootSlice)
      ) {
        break
      }
      const curLinks = this.sliceLinksBySliceId.get(curRootSlice.id)
      if (curLinks != null) {
        for (const curLink of curLinks) {
          if (variantSlices.has(curLink.fromSliceId)) {
            continue
          }

          const fromSlice = this.sliceById.get(curLink.fromSliceId)!
          const toSlice = this.sliceById.get(curLink.toSliceId)!
          const nonVisibleError = getNonVisibleConnectionError(
            fromSlice,
            toSlice,
            this.sliceById,
            this.sliceLinksBySliceId,
          )
          if (nonVisibleError != null) {
            continue
          }

          variantSlices.add(curLink.fromSliceId)
        }
      }
    }

    return Array.from(variantSlices).map((variantSliceId) =>
      this.mapVariant(cycleSlice.id, variantSliceId, CycleVariantType.SIBLING),
    )
  }

  private calculateChainNetworkRequestTimeConsume(slices: Slice[]) {
    return slices.reduce((sum, slice) => {
      if (slice.isNetworkRequest || slice.stackNetworkRequests?.length) {
        return sum + (slice.end - slice.start) / this.traceDataState.traceEnd
      }
      return sum
    }, 0)
  }

  private calculateChainNumberOfLoggedEvents(slices: Slice[]) {
    return slices.reduce((sum, slice) => {
      if (slice.isLoggedEvent || (slice.root && slice.root.isLoggedEvent)) {
        return sum + 1
      }
      return sum
    }, 0)
  }

  /**
   * We should filter out variants with same ending slice and keep in mind to keep variant with longest chain
   * In case when we have multiple variants with same ending and chain length - we should keep only first variant
   * (check if second step in chain has same root will )
   **/
  private filterCycleVariantsObviousDuplicates(
    cycleSlice: Slice,
    variants: CycleVariant[],
  ): CycleVariant[] {
    const hasLegacyPinnedVariant = this.cycleLegacyPinnedVariantIndexLookup.has(cycleSlice.id)
    let legacyPinnedVariant: CycleVariant

    const groupedVariants: Record<string, CycleVariant[]> = variants.reduce((grouped, variant) => {
      const key = `${variant.chainThreadIDs}-${variant.chainLastSliceId}`
      if (!grouped[key]) {
        grouped[key] = []
      }
      grouped[key].push(variant)
      return grouped
    }, {} as Record<string, CycleVariant[]>)

    const reducedFromGrouped: CycleVariant[] = []

    for (const key in groupedVariants) {
      const group = groupedVariants[key]
      // list is sorted by type (between slices/child slices) and grouped in order from begining to end of trace
      // it's better to select not furthest slice in group but earliest to avoid improper connection between cycle of path
      reducedFromGrouped.push(group[0])
    }

    if (hasLegacyPinnedVariant) {
      const cycleVariantLegacyIndex = this.cycleLegacyPinnedVariantIndexLookup.get(cycleSlice.id)!
      legacyPinnedVariant = this.legacyCyclePathVariants(cycleSlice)[cycleVariantLegacyIndex]

      const pinnedIndex = reducedFromGrouped.some(
        (variant) => variant.sliceId === legacyPinnedVariant.sliceId,
      )
      if (!pinnedIndex) {
        reducedFromGrouped.push(legacyPinnedVariant)
      }
    }

    return reducedFromGrouped
  }

  private legacyCyclePathVariants(cycleSlice: Slice): CycleVariant[] {
    if (!this.cycleLegacyVariantsBySliceId.has(cycleSlice.id)) {
      const variants = TraceAnalyzeStore.isFramePathLookupSlice(cycleSlice)
        ? this.getCycleSliceVariantsFromMainThread(cycleSlice)
        : [
            ...this.getCycleSliceChildVariants(cycleSlice),
            ...this.getCycleSliceSiblingVariants(cycleSlice),
          ]
      this.cycleLegacyVariantsBySliceId.set(cycleSlice.id, variants)
      return variants
    } else {
      return this.cycleLegacyVariantsBySliceId.get(cycleSlice.id)!
    }
  }

  private connectCyclePathVariants(variants: CycleVariant[]) {
    for (const variant of variants) {
      const { chainLastSliceId } = variant
      const chainLastSlice = this.sliceById.get(chainLastSliceId)!
      if (TraceAnalyzeStore.isCycleSlice(chainLastSlice)) {
        if (!this.cyclePinnedVariantIndexLookup.has(chainLastSliceId)) {
          this.setCycleLink(chainLastSliceId, 0)
        }
      }
    }
  }

  private cyclePathVariants(cycleSlice: Slice): CycleVariant[] {
    if (!this.cycleProcessedVariantsBySliceId.has(cycleSlice.id)) {
      const legacyVariants = this.legacyCyclePathVariants(cycleSlice)
      const processedVariants = this.isCycleFiltrationEnabled
        ? this.filterCycleVariantsObviousDuplicates(cycleSlice, legacyVariants)
        : legacyVariants.slice()

      processedVariants.sort(
        (variantA, variantB) =>
          this.calculateChainNetworkRequestTimeConsume(variantB.chain) +
          this.calculateChainNumberOfLoggedEvents(variantB.chain) -
          (this.calculateChainNetworkRequestTimeConsume(variantA.chain) +
            this.calculateChainNumberOfLoggedEvents(variantA.chain)),
      )
      this.cycleProcessedVariantsBySliceId.set(cycleSlice.id, processedVariants)
      this.connectCyclePathVariants(processedVariants)
      return processedVariants
    } else {
      return this.cycleProcessedVariantsBySliceId.get(cycleSlice.id)!
    }
  }

  private cycleIndexFromLegacyToSorted(cycleSlice: Slice, cycleVariantLegacyIndex: number): number {
    const legacyVariant = this.legacyCyclePathVariants(cycleSlice)[cycleVariantLegacyIndex]

    return this.cyclePathVariants(cycleSlice).findIndex(
      (variant) => variant.sliceId === legacyVariant.sliceId,
    )
  }

  private cycleIndexFromSortedToLegacy(cycleSlice: Slice, cycleVariantSortedIndex: number): number {
    const sortedVariant = this.cyclePathVariants(cycleSlice)[cycleVariantSortedIndex]

    return this.legacyCyclePathVariants(cycleSlice).findIndex(
      (variant) => variant.sliceId === sortedVariant.sliceId,
    )
  }

  cycleSliceTotalVariants(slice: Slice) {
    return this.cyclePathVariants(slice).length
  }

  private updateCycleSliceIndexes(previousState: {
    pinned: Map<number, number>
    selected: Map<number, number>
  }): Promise<void> {
    const { projectUrlName } = this.chartPageParams
    const { traceProjectLocalId } = this.chartPageParams

    const reqBody = Array.from(this.cyclePinnedVariantIndexLookup.entries()).map(
      ([sliceId, pathId]) => {
        const cycleSlice = this.sliceById.get(sliceId)!
        return {
          sliceId,
          pathId: this.cycleIndexFromSortedToLegacy(cycleSlice, pathId),
        }
      },
    )

    return this.api
      .putChoreographerPaths(
        { projectUrlName, traceProjectLocalId },
        { choreographerPath: [...reqBody] },
      )
      .then((paths) => {
        this.pinCycleVariants(paths)
      })
      .catch((reason) => {
        if (previousState) {
          this.cyclePinnedVariantIndexLookup = previousState.pinned
          this.cycleSelectedVariantIndexLookup = previousState.selected
        }
        return Promise.reject(reason)
      })
  }

  isCycleSliceHasPinnedVariant(slice: Slice) {
    return this.cyclePinnedVariantIndexLookup.has(slice.id)
  }

  isCurrentCycleSliceVariantPinned(slice: Slice) {
    const selectedCycleSliceVariantIndex = this.getCycleSliceCurrentVariantIndex(slice)
    const pinnedCycleSliceVariantIndex = this.cyclePinnedVariantIndexLookup.get(slice.id)
    return selectedCycleSliceVariantIndex === pinnedCycleSliceVariantIndex
  }

  togglePinCycleSliceVariantIndex(slice: Slice): Promise<void> {
    const previousState = {
      pinned: this.cyclePinnedVariantIndexLookup,
      selected: this.cycleSelectedVariantIndexLookup,
    }

    // optimistic update
    if (this.isCurrentCycleSliceVariantPinned(slice)) {
      this.cycleSelectedVariantIndexLookup.set(
        slice.id,
        this.cyclePinnedVariantIndexLookup.get(slice.id)!,
      )
      this.cyclePinnedVariantIndexLookup.delete(slice.id)
    } else {
      this.cyclePinnedVariantIndexLookup.set(
        slice.id,
        this.cycleSelectedVariantIndexLookup.get(slice.id)!,
      )
      this.cycleSelectedVariantIndexLookup.delete(slice.id)
    }

    // update the server
    return this.updateCycleSliceIndexes(previousState)
  }

  cycleSlicePinState(slice: Slice) {
    if (this.isCurrentCycleSliceVariantPinned(slice)) {
      return {
        tooltip: 'unselectPath',
        icon: 'star-active',
      }
    } else if (this.isCycleSliceHasPinnedVariant(slice)) {
      return {
        tooltip: 'updateSelectedPath',
        icon: 'star-inactive',
      }
    } else {
      return {
        tooltip: 'selectThisPath',
        icon: 'star-inactive',
      }
    }
  }

  selectCycleSliceNextVariant(slice: Slice) {
    const currentSelectedVariantIndex = this.getCycleSliceCurrentVariantIndex(slice)
    const totalVariants = this.cycleProcessedVariantsBySliceId.get(slice.id)!.length
    const nextValue =
      currentSelectedVariantIndex + 1 >= totalVariants ? 0 : currentSelectedVariantIndex + 1

    this.selectCycleSliceVariant(slice.id, nextValue)
  }

  selectCycleSlicePreviousVariant(slice: Slice) {
    const currentSelectedVariant = this.getCycleSliceCurrentVariantIndex(slice)
    const totalVariants = this.cycleProcessedVariantsBySliceId.get(slice.id)!.length
    const previousValue =
      currentSelectedVariant - 1 < 0 ? Math.max(totalVariants - 1, 0) : currentSelectedVariant - 1

    this.selectCycleSliceVariant(slice.id, previousValue)
  }

  getSlicesFromTree(inputTree: TreeNode | null): Slice[] {
    const slices: Slice[] = []

    walkOverTree(inputTree, (node: TreeNode) => {
      if (node.sliceId != null) {
        const slice = this.sliceById.get(node.sliceId)
        if (slice !== undefined) {
          slices.push(slice)
        }
      }
    })

    return slices
  }

  get allRegularSlices(): Slice[] {
    return this.getSlicesFromTree(this.allRegularLinksTree)
  }

  get mainRegularSlices(): Slice[] {
    return getMainChainFromTree(this.allRegularLinksTree, this.sliceById)
  }

  get mainRegularRange(): SliceRange | undefined {
    const slices = this.mainRegularSlices
    const slicesCount = slices.length
    if (slicesCount) {
      const { end } = slices[0]
      const { start } = slices[slicesCount - 1]
      return { start, end }
    } else {
      return undefined
    }
  }

  get allNativeSlices(): Slice[] {
    return this.getSlicesFromTree(this.allNativeLinksTree)
  }

  get mainNativeSlices(): Slice[] {
    return getMainChainFromTree(this.allNativeLinksTree, this.sliceById)
  }

  get mainNativeRange(): SliceRange | undefined {
    const slices = this.mainNativeSlices
    const slicesCount = slices.length
    if (slicesCount) {
      const { end } = slices[0]
      const { start } = slices[slicesCount - 1]
      return { start, end }
    } else {
      return undefined
    }
  }

  get allChainsSlices(): Slice[] {
    return [...this.allRegularSlices, ...this.allNativeSlices]
  }

  get mainChainSlices(): Slice[] {
    return [...this.mainRegularSlices, ...this.mainNativeSlices]
  }

  get mainChainRange(): SliceRange | undefined {
    if (this.mainRegularRange !== undefined && this.mainNativeRange !== undefined) {
      return {
        start: Math.min(this.mainRegularRange.start, this.mainNativeRange.start),
        end: Math.max(this.mainRegularRange.end, this.mainNativeRange.end),
      }
    } else if (this.mainRegularRange !== undefined) {
      const { start, end } = this.mainRegularRange
      return { start, end }
    } else if (this.mainNativeRange !== undefined) {
      const { start, end } = this.mainNativeRange
      return { start, end }
    }

    return undefined
  }

  get mainDetailsSlices(): Slice[] {
    return this.isDetailsChainNative ? this.mainNativeSlices : this.mainRegularSlices
  }

  get isDetailsChainNative(): boolean {
    return this.detailsChainType === DetailsChainType.NATIVE
  }

  get isDetailsChainRegular(): boolean {
    return this.detailsChainType === DetailsChainType.REGULAR
  }

  switchDetailsToNativeChain() {
    this.detailsChainType = DetailsChainType.NATIVE
  }

  switchDetailsToRegularChain() {
    this.detailsChainType = DetailsChainType.REGULAR
  }

  get chainExists(): boolean {
    return this.mainChainSlices.length > 1
  }

  get regularChainExists(): boolean {
    return this.mainRegularSliceIds.size > 1
  }

  get nativeChainExists(): boolean {
    return this.mainNativeSlicesIds.size > 1
  }

  get allPathsLinksTree(): TreeNode | null {
    return mergeTreeNodes(this.allRegularLinksTree, this.allNativeLinksTree)
  }

  get mainPathLinksTree(): TreeNode | null {
    return mergeTreeNodes(this.mainRegularLinksTree, this.mainNativeLinksTree)
  }

  get linksTree(): TreeNode | null {
    if (this.shouldShowAllPaths) {
      return this.allPathsLinksTree
    }
    return this.mainPathLinksTree
  }

  private getConnectionType(toSliceId: number) {
    if (this.mainRegularSliceIds.has(toSliceId)) {
      return this.isDetailsChainRegular ? LineType.PRIMARY : LineType.UNFOCUSED
    }
    if (this.mainNativeSlicesIds.has(toSliceId)) {
      return this.isDetailsChainNative ? LineType.PRIMARY : LineType.UNFOCUSED
    }
    return LineType.SECONDARY
  }

  private getBorderType(slice: Slice): BorderType {
    if (this.selectedSlice?.id === slice.id) {
      return BorderType.ACTIVE
    }
    if (this.mainRegularSliceIds.has(slice.id)) {
      return this.isDetailsChainRegular ? BorderType.PRIMARY : BorderType.UNFOCUSED
    }
    if (this.mainNativeSlicesIds.has(slice.id)) {
      return this.isDetailsChainNative ? BorderType.PRIMARY : BorderType.UNFOCUSED
    }
    return BorderType.SECONDARY
  }

  get chainNodes(): ChainNodeData[] {
    if (this.shouldShowAllPaths) {
      return this.allChainsSlices.map((slice) => this.getChainNodeData(slice))
    }
    return this.mainChainSlices.map((slice) => this.getChainNodeData(slice))
  }

  getChainNodeData(slice: Slice): ChainNodeData {
    const thread = this.threadsById.get(slice.threadId)!
    const threadActiveLevels = this.activeLevelsFromChainByThreadId.get(slice.threadId) ?? []
    const borderType = this.getBorderType(slice)
    return {
      slice,
      borderType,
      thread,
      threadActiveLevels,
    }
  }

  get chainConnections(): ConnectionData[] {
    const { mainChainIds } = this
    const connections: ConnectionData[] = []
    walkOverTree(this.linksTree, (treeNode) => {
      if (treeNode.fromLinks.length == null) {
        return null
      }
      let { fromLinks } = treeNode
      const linksType = [...new Set(fromLinks.map((link) => link.connectionType))]
      const sameTreeTypeLinks = linksType.length === 1 && linksType[0] === ConnectionType.TREE
      /**
       * There could be multiple connections from children slice to different parents with different levels
       * We draw vertical same-tree connections only between slices from main chain and draw it to the closest parent.
       * First parent in array should be always closest.
       */
      if (sameTreeTypeLinks) {
        fromLinks = [fromLinks[0]]
      }
      for (const link of fromLinks) {
        const toSliceId = link.toTreeNode.sliceId
        const fromSliceId = link.fromTreeNode.sliceId
        if (
          this.shouldShowAllPaths &&
          link.connectionType === ConnectionType.TREE &&
          !mainChainIds.has(toSliceId)
        ) {
          continue
        }
        /**
         * In some cases named links can trigger to connect two slices with alike names
         * to same "FROM" slice. While these "TO" slices already linked with each other by auto type link.
         */
        if (this.checkMainChainSliceOrder(fromSliceId, toSliceId)) {
          continue
        }
        const { connectionType } = link
        const lineType = this.getConnectionType(toSliceId)
        connections.push({ fromSliceId, toSliceId, connectionType, lineType })
      }
    })
    return connections
  }

  /*
   * Some data needs to be calculated only for main execution path
   * and updated such as:
   * Chain delays
   * Chain of slice ids which includes ancestor slice ids which connects slices with links
   **/
  get chainDelays(): ChainDelayData[] {
    const delays: ChainDelayData[] = []
    const maxTime = this.traceDataState.traceEnd

    // we don't need delays from additional branches of execution path - just main
    walkOverTree(this.mainPathLinksTree, (item) => {
      const links = item.fromLinks
      links.forEach((fromLink) => {
        const sliceStart = this.sliceById.get(fromLink.toTreeNode.sliceId)
        const sliceEnd = this.sliceById.get(fromLink.fromTreeNode.sliceId)
        if (!sliceStart || !sliceEnd) {
          return
        }

        // delay between same root slices shouldn't be accounted as long it's not a worker root
        if (sliceStart.root?.id === sliceEnd.root?.id && sliceStart.root?.end !== maxTime) {
          return
        }

        const start = sliceStart.end
        const end = sliceEnd.start

        if (end > start) {
          delays.push({
            start,
            end,
          })
        }
      })
    })

    return delays
  }

  /**
   * creates array of slice's ids of execution path including parents/ancestors between two slices with connection within one tree
   * used in view mode {@link shouldDimDisconnectedSlices}
   */
  get mainChainIdsWithParents(): Set<number> {
    const tree = this.mainPathLinksTree
    const ids = new Set<number>()

    walkOverTree(tree, (node) => {
      ids.add(node.sliceId)
      const connectionsTypes = [
        ...new Set(node.fromLinks.map(({ connectionType }) => connectionType)),
      ]
      const onlyTreeConnections =
        connectionsTypes.length === 1 && connectionsTypes[0] === ConnectionType.TREE
      if (onlyTreeConnections) {
        const ancestorSliceId = node.fromLinks[0].toTreeNode.sliceId
        let parentSlice = this.sliceById.get(node.sliceId)!.parent!
        while (parentSlice.id !== ancestorSliceId) {
          ids.add(parentSlice.id)
          parentSlice = parentSlice.parent!
        }
      }
    })

    return ids
  }

  filterPathLinksTreeByChainIds(
    initialTree: TreeNode | null,
    chainIds: Set<number>,
  ): TreeNode | null {
    if (initialTree == null) {
      return initialTree
    }
    const nodesCopyMap = new Map<number, TreeNode>()
    walkOverTree(initialTree, (treeNode) => {
      if (!chainIds.has(treeNode.sliceId)) {
        return null
      }
      nodesCopyMap.set(treeNode.sliceId, { ...treeNode })
    })
    nodesCopyMap.forEach((treeNode) => {
      treeNode.fromLinks = treeNode.fromLinks
        .filter((link) => chainIds.has(link.toTreeNode.sliceId))
        .map((link) => ({
          connectionType: link.connectionType,
          fromTreeNode: nodesCopyMap.get(link.fromTreeNode.sliceId)!,
          toTreeNode: nodesCopyMap.get(link.toTreeNode.sliceId)!,
        }))
    })
    return nodesCopyMap.get(initialTree.sliceId)!
  }

  getLinksTreeBySliceId(sliceId: number): TreeNode | null {
    if (!this.sliceById.has(sliceId)) {
      return null
    }
    const slice = this.sliceById.get(sliceId)!

    if (this.isBeConnectionsEnabled) {
      return getLinksTreeSimple(
        slice,
        this.sliceLinksBySliceId,
        this.sliceById,
        this.cycleSelectedVariantIndexLookup,
        this.cyclePinnedVariantIndexLookup,
      )
    }
    return getLinksTree(slice, this.sliceLinksBySliceId, this.sliceById)
  }

  getMainChainSlicesBySliceId(sliceId: number): Slice[] {
    return getMainChainFromTree(this.getLinksTreeBySliceId(sliceId), this.sliceById)
  }

  get mainRegularLinksTree(): TreeNode | null {
    return this.filterPathLinksTreeByChainIds(this.allRegularLinksTree, this.mainChainIds)
  }

  get allRegularLinksTree(): TreeNode | null {
    if (this.selectedSlice === null) {
      return null
    }
    return this.getLinksTreeBySliceId(this.selectedSlice.id)
  }

  get allNativeLinksTree(): TreeNode | null {
    if (this.selectedSlice === null) {
      return null
    }
    if (!this.traceDataState.reactJSThreadIds.has(this.selectedSlice.threadId)) {
      return null
    }
    if (this.isCycleSliceSelected) {
      return null
    }
    if (this.traceDataState.reactQueueThread !== null) {
      const treeNode = { sliceId: this.selectedSlice!.id, fromLinks: [], toLinks: [] }
      const queueThreadSlice = findSlice(
        this.traceDataState.reactQueueThread.slices,
        (queueSlice) => {
          return (
            queueSlice.level > 0 &&
            queueSlice.start <= this.selectedSlice!.end &&
            queueSlice.end >= this.selectedSlice!.end
          )
        },
      )
      if (queueThreadSlice !== null) {
        const mqtTreeNode = this.isBeConnectionsEnabled
          ? getLinksTreeSimple(
              queueThreadSlice,
              this.sliceLinksBySliceId,
              this.sliceById,
              this.cycleSelectedVariantIndexLookup,
              this.cyclePinnedVariantIndexLookup,
            )!
          : getLinksTree(queueThreadSlice, this.sliceLinksBySliceId, this.sliceById, true)!
        linkTreeNodes(treeNode, mqtTreeNode, ConnectionType.CLOSURE)
      }
      return treeNode
    }
    return null
  }

  get mainNativeLinksTree(): TreeNode | null {
    return this.filterPathLinksTreeByChainIds(this.allNativeLinksTree, this.mainNativeSlicesIds)
  }

  get mainChainIds(): Set<number> {
    return new Set<number>(this.mainChainSlices.map(({ id }) => id))
  }

  get allChainsIds(): Set<number> {
    return new Set<number>(this.allChainsSlices.map(({ id }) => id))
  }

  get mainRegularSliceIds(): Set<number> {
    return new Set<number>(this.mainRegularSlices.map(({ id }) => id))
  }

  get mainNativeSlicesIds(): Set<number> {
    return new Set<number>(this.mainNativeSlices.map(({ id }) => id))
  }

  get hasAlternativeChains(): boolean {
    return this.allChainsIds.size !== this.mainChainIds.size
  }

  get mainRegularSliceIdIndexes(): Map<number, number> {
    const indexedSlice = new Map<number, number>()
    this.mainRegularSlices.map((slice, index) => indexedSlice.set(slice.id, index))
    return indexedSlice
  }

  get mainNativeSliceIdIndexes(): Map<number, number> {
    const indexedSlice = new Map<number, number>()
    this.mainNativeSlices.map((slice, index) => indexedSlice.set(slice.id, index))
    return indexedSlice
  }

  /**
   * It's reverse order function (from right to left). As "from" can be in both native and regular chain
   * we should check link order in chain (regular/native) based on "to" slice
   */
  checkMainChainSliceOrder(fromId: number, toId: number) {
    if (this.mainChainIds.has(fromId)) {
      if (this.nativeChainExists && this.mainNativeSlicesIds.has(toId)) {
        return (
          this.mainNativeSliceIdIndexes.get(toId)! - this.mainNativeSliceIdIndexes.get(fromId)! !==
          1
        )
      }
      if (this.regularChainExists && this.mainRegularSliceIds.has(toId)) {
        return (
          this.mainRegularSliceIdIndexes.get(toId)! -
            this.mainRegularSliceIdIndexes.get(fromId)! !==
          1
        )
      }
    }
    return false
  }

  get activeThreadIdsFromChain(): Set<number> {
    let slices: Slice[]
    if (this.shouldShowAllPaths) {
      slices = this.allChainsSlices
    } else {
      slices = this.mainChainSlices
    }
    return new Set(slices.map((slice) => slice.threadId))
  }

  /**
   * Goes through all slices of execution path (main or all chains) and get for each
   * used/active thread in execution path {@link activeThreadIdsFromChain} it's used/active levels
   */
  get activeLevelsFromChainByThreadId(): LevelsByThreadId {
    const activeLevelsByThreadId = new Map<number, number[]>()
    const slices = this.shouldShowAllPaths ? this.allChainsSlices : this.mainChainSlices

    const threadsIds = [...this.activeThreadIdsFromChain]
    for (const threadId of threadsIds) {
      const levels = []
      for (const pathSlice of slices) {
        if (pathSlice.threadId === threadId) {
          levels.push(pathSlice.level)
          const childNetworkRequests = getChildNetworkRequests(pathSlice)
          if (childNetworkRequests.length) {
            for (const childSlice of childNetworkRequests) {
              levels.push(childSlice.level)
            }
          }
        }
      }
      const uniqueLevels = [...new Set<number>(levels)].sort((prev, next) => prev - next)
      activeLevelsByThreadId.set(threadId, uniqueLevels)
    }
    return activeLevelsByThreadId
  }
}
