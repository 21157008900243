import { DashboardRunDto } from 'api/__generated__/api-types'

export const isFinished = (run: DashboardRunDto): boolean => {
  switch (run.status) {
    case 'CANCELLED':
    case 'FAILED':
    case 'FINISHED':
      return true
    case 'ANALYZING':
    case 'UNDEFINED':
    case 'CANCEL_REQUESTED':
    case 'AWAITING_UPLOAD':
    case 'STARTED':
    case 'READY_TO_START':
      return false
  }
}

export enum RegressionStatus {
  NOT_DETECTED,
  SLOWDOWN,
  SPEEDUP,
}

export const getRegressionStatus = (run: DashboardRunDto): RegressionStatus => {
  const curStats = run.results.current.nonInstrumented?.statistics
  const prevStats = run.results.previous?.nonInstrumented?.statistics
  if (!curStats || !prevStats) {
    return RegressionStatus.NOT_DETECTED
  }
  if (!run.results.comparison.regressionDetected) {
    return RegressionStatus.NOT_DETECTED
  }

  if (curStats.meanValue < prevStats.meanValue) {
    return RegressionStatus.SPEEDUP
  } else {
    return RegressionStatus.SLOWDOWN
  }
}

export const prettifyDate = (date: string | null | undefined): string => {
  if (!date || date == 'null') {
    return ''
  } else {
    return new Date(date).toLocaleString()
  }
}

export const isRunFailed = (run: DashboardRunDto): boolean => {
  return run.status === 'FAILED'
}
