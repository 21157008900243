import { DashboardRunDto } from 'api/__generated__/api-types'
import { isFinished, isRunFailed } from 'components/flows/ra/runs/runUtils'
import React from 'react'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { Header } from './details/Header'
import { Builds } from './details/Builds'
import { Statistics } from './details/Statistics'
import { Processing } from './details/Processing'
import { Data } from './details/Data'
import { InstrumentedResults } from './details/InstrumentedResults'

interface RunDetailsProps {
  raStore: RaMvpStore
  run: DashboardRunDto
}

export const RunDetails = ({ raStore, run }: RunDetailsProps) => {
  if (!isFinished(run)) {
    return <Processing />
  }
  const isFailed = isRunFailed(run)
  return (
    <div className="px-4 space-y-4">
      <Header run={run} />
      {!isFailed && (
        <>
          <Builds run={run} />
          <Statistics run={run} />
          <Data run={run} />
          {run.results.current.instrumented && <InstrumentedResults raStore={raStore} run={run} />}
        </>
      )}
    </div>
  )
}
