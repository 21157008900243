import React from 'react'
import { DashboardRunDto } from 'api/__generated__/api-types'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from 'utils/stringUtils'

interface RunErrorProps {
  run: DashboardRunDto
}

export const RunError = ({ run }: RunErrorProps) => {
  const { t } = useTranslation()
  if (run == null) {
    return null
  }
  const curNonInstrErr = run.results.current.nonInstrumented?.error?.message
  const curInstrErr = run.results.current.instrumented?.error?.message
  const prevNonInstrErr = run.results.previous?.nonInstrumented?.error?.message
  const prevInstrErr = run.results.previous?.instrumented?.error?.message
  return (
    <div>
      <p className="space-x-1">
        <span>{t('ra.flow.run.status')}</span>
        <span className="text-white">{capitalizeFirstLetter(run.status.toLowerCase())}</span>
      </p>
      {(run.errors?.length ?? 0) > 0 && (
        <div>
          <p>Root errors</p>
          <ul>
            {run.errors
              ?.filter((value) => value != null)
              .map((error, index) => (
                <li key={index}>{error.message}</li>
              ))}
          </ul>
        </div>
      )}
      {curNonInstrErr && (
        <ErrorPara title={'Current build non-instrumented error'} message={curNonInstrErr} />
      )}
      {curInstrErr && (
        <ErrorPara title={'Previous build instrumented run error'} message={curInstrErr} />
      )}
      {prevNonInstrErr && (
        <ErrorPara title={'Previous build non-instrumented error'} message={prevNonInstrErr} />
      )}
      {prevInstrErr && (
        <ErrorPara title={'Current build instrumented run error'} message={prevInstrErr} />
      )}
    </div>
  )
}

interface ErrorParaProps {
  title: string
  message: string
}

export const ErrorPara = ({ title, message }: ErrorParaProps) => {
  return (
    <>
      <p>{title}</p>
      <pre className="ml-6 text-white text-small whitespace-pre-wrap break-all">{message}</pre>
    </>
  )
}
