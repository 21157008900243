import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Modal'
import {
  FlowAutomationConfigDtoBuild,
  FlowDto,
  FlowReq,
  ProjectDto,
  UpdateFlowAutomationConfigDto,
} from 'api/models'
import { useApi } from 'contexts/di-context'
import { SettingsInput } from 'components/flows/ra/settings/modal/SettingsInput'
import { FlowAutomationConfigDtoPriorityDto, ScriptKindDto } from 'api/__generated__/api-constants'
import { SettingsSelect } from 'components/flows/ra/settings/modal/SettingsSelect'
import { SelectOption } from 'components/Select'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { SettingsAutomationInput } from 'components/flows/ra/settings/modal/SettingsAutomationInput'
import { extractFileName } from 'utils/stringUtils'
import { arraysAreEqual } from 'utils/arrays'
import { queryKeys } from 'hooks/useApiQuery'
import { useQueryClient } from 'react-query'
import NProgress from 'nprogress'
import { useToaster } from 'hooks/useToaster'
import { SettingsCheckBox } from 'components/flows/ra/settings/modal/SettingsCheckBox'
import {
  InstrumentedRunCondition,
  InstrumentedRunConditionValue,
  Priority,
  PRIORITY_OPTIONS,
  PriorityValue,
  RUN_CONDITION_OPTIONS,
} from 'components/flows/ra/settings/settingUtils'

interface SettingsModalProps {
  isOpen: boolean
  onClose: () => void
  flowDto: FlowDto
  projectDto: ProjectDto
  raStore: RaMvpStore
  isAdmin: boolean
}

export const SettingsModal = (props: SettingsModalProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const toaster = useToaster()

  const { isOpen, onClose, flowDto, projectDto, raStore, isAdmin } = props

  const [flowReq] = useState<FlowReq>({
    projectUrlName: projectDto.urlName,
    flowProjectLocalId: String(flowDto.projectLocalId),
  })

  const [userFlowId, setUserFlowId] = useState('')
  const [deviceIds, setDeviceIds] = useState<number[]>([])
  const [priority, setPriority] = useState<PriorityValue>(Priority.REGULAR)
  const [runCount, setRunCount] = useState<number>(0)
  const [runCountInstrumented, setRunCountInstrumented] = useState(0)
  const [switchFreq, setSwitchFreq] = useState(0)
  const [forceStop, setForceStop] = useState(true)
  const [clearData, setClearData] = useState(true)
  const [runCondition, setRunCondition] = useState<InstrumentedRunConditionValue>(
    InstrumentedRunCondition.ON_ANY_CHANGE,
  )
  const [automationFile, setAutomationFile] = useState<File | null>(null)
  const [automationFileName, setAutomationFileName] = useState('')
  const [automationAppId, setAutomationAppId] = useState('')
  const [automationInstrumentOptionClass, setAutomationInstrumentOptionClass] = useState('')
  const [setupFile, setSetupFile] = useState<File | null>(null)
  const [setupFileName, setSetupFileName] = useState('')
  const [setupAppId, setSetupAppId] = useState('')
  const [setupInstrumentOptionClass, setSetupInstrumentOptionClass] = useState('')

  useEffect(() => {
    if (isOpen) {
      setUserFlowId(flowDto.automationConfig?.raUserFlowId ?? '')
      setDeviceIds(flowDto.automationConfig?.devices.map((device) => device.id) ?? [])
      setPriority(flowDto.automationConfig?.priority ?? FlowAutomationConfigDtoPriorityDto.REGULAR)
      setRunCount(flowDto.automationConfig?.runCount ?? 0)
      setRunCountInstrumented(flowDto.automationConfig?.runCountInstrumented ?? 0)
      setAutomationFileName(
        extractFileName(flowDto.automationConfig?.automationScript?.build?.name) ?? '',
      )
      setAutomationAppId(flowDto.automationConfig?.automationScript?.appId ?? '')
      setAutomationInstrumentOptionClass(
        flowDto.automationConfig?.automationScript?.instrumentOptionsClass ?? '',
      )
      setSetupFileName(extractFileName(flowDto.automationConfig?.setupScript?.build?.name) ?? '')
      setSetupAppId(flowDto.automationConfig?.setupScript?.appId ?? '')
      setSetupInstrumentOptionClass(
        flowDto.automationConfig?.setupScript?.instrumentOptionsClass ?? '',
      )
      setSwitchFreq(flowDto.automationConfig?.switchFrequency ?? 0)
      setForceStop(flowDto.automationConfig?.forceStop ?? true)
      setClearData(flowDto.automationConfig?.clearData ?? true)
      setRunCondition(
        flowDto.automationConfig?.instrumentedRunCondition ??
          InstrumentedRunCondition.ON_ANY_CHANGE,
      )
    }
  }, [isOpen, flowDto.automationConfig])

  const hasChanges = useMemo((): boolean => {
    const config = flowDto.automationConfig
    if (!config) {
      return false
    }
    const deviceIdsAreEqual = arraysAreEqual(
      deviceIds,
      config.devices.map((device) => device.id),
    )
    return (
      config.raUserFlowId.trim() !== userFlowId.trim() ||
      !deviceIdsAreEqual ||
      priority !== config.priority ||
      runCount !== config.runCount ||
      runCountInstrumented !== config.runCountInstrumented ||
      switchFreq !== (config.switchFrequency ?? 0) ||
      forceStop !== (config.forceStop ?? true) ||
      clearData !== (config.clearData ?? true) ||
      runCondition !==
        (config.instrumentedRunCondition ?? InstrumentedRunCondition.ON_ANY_CHANGE) ||
      automationFile != null ||
      automationAppId.trim() !== (config.automationScript?.appId?.trim() ?? '') ||
      automationInstrumentOptionClass !== (config.automationScript?.instrumentOptionsClass ?? '') ||
      setupFile != null ||
      setupAppId !== (config.setupScript?.appId ?? '') ||
      setupInstrumentOptionClass !== (config.setupScript?.instrumentOptionsClass ?? '')
    )
  }, [
    flowDto.automationConfig,
    deviceIds,
    userFlowId,
    priority,
    runCount,
    runCountInstrumented,
    switchFreq,
    forceStop,
    clearData,
    runCondition,
    automationFile,
    automationAppId,
    automationInstrumentOptionClass,
    setupFile,
    setupAppId,
    setupInstrumentOptionClass,
  ])

  const createDto = useCallback(
    (
      automationBuild: FlowAutomationConfigDtoBuild | null | undefined,
      setupBuild: FlowAutomationConfigDtoBuild | null | undefined,
    ): UpdateFlowAutomationConfigDto => {
      return {
        raUserFlowId: userFlowId,
        deviceIds: deviceIds,
        priority: priority,
        runCount: runCount,
        instrumentedRunCount: runCountInstrumented,
        switchFrequency: switchFreq,
        forceStop: forceStop,
        clearData: clearData,
        instrumentedRunCondition: runCondition,
        automationScriptConfig: {
          build: automationBuild,
          appId: automationAppId,
          instrumentOptionsClass: automationInstrumentOptionClass,
        },
        setupScriptConfig: {
          build: setupBuild,
          appId: setupAppId,
          instrumentOptionsClass: setupInstrumentOptionClass,
        },
      }
    },
    [
      automationAppId,
      automationInstrumentOptionClass,
      clearData,
      deviceIds,
      forceStop,
      runCondition,
      priority,
      runCount,
      runCountInstrumented,
      setupAppId,
      setupInstrumentOptionClass,
      switchFreq,
      userFlowId,
    ],
  )

  const deviceOptions: SelectOption<number>[] = raStore.devices.map(
    (device): SelectOption<number> => ({
      value: device.id,
      label: device.name,
    }),
  )

  const onSubmit = useCallback(() => {
    if (!hasChanges) {
      return
    }

    let automationBuild: FlowAutomationConfigDtoBuild | null | undefined =
      flowDto.automationConfig?.automationScript?.build
    let setupBuild: FlowAutomationConfigDtoBuild | null | undefined =
      flowDto.automationConfig?.setupScript?.build

    NProgress.start()
    let promise = Promise.resolve()
    if (automationFile != null) {
      promise = promise.then(() =>
        api.postFlowScript(flowReq, automationFile, ScriptKindDto.AUTOMATION).then((resp) => {
          automationBuild = resp.automationConfig?.automationScript?.build
          return
        }),
      )
    }
    if (setupFile != null) {
      promise = promise.then(() =>
        api.postFlowScript(flowReq, setupFile, ScriptKindDto.SETUP).then((resp) => {
          setupBuild = resp.automationConfig?.setupScript?.build
          return
        }),
      )
    }
    promise.then(() => {
      raStore
        .updateRunConfig(createDto(automationBuild, setupBuild))
        .then((resp) => {
          queryClient.setQueryData<FlowDto | undefined>(
            queryKeys.flow({
              projectUrlName: projectDto.urlName,
              flowProjectLocalId: String(flowDto.projectLocalId),
            }),
            () => resp,
          )
          onClose()
        })
        .catch((reason) => toaster.error(reason, 'ra.flow.settings.modalErrorTitle'))
        .finally(() => {
          NProgress.done()
        })
    })
  }, [
    api,
    automationFile,
    createDto,
    flowDto.automationConfig?.automationScript?.build,
    flowDto.automationConfig?.setupScript?.build,
    flowDto.projectLocalId,
    flowReq,
    hasChanges,
    onClose,
    projectDto.urlName,
    queryClient,
    raStore,
    setupFile,
    toaster,
  ])

  return (
    <Modal
      title={t('ra.tabs.settings')}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={{
        children: t('save'),
        onClick: onSubmit,
        disabled: !hasChanges,
      }}
    >
      <div className="w-full text-normal text-gray-normal space-y-4">
        <SettingsInput
          label={t('ra.flow.settings.userFlowId.label')}
          value={userFlowId}
          onChange={(value) => setUserFlowId(String(value))}
        />
        <SettingsSelect
          label={t('ra.flow.settings.devices.label')}
          options={deviceOptions}
          value={deviceIds}
          onChange={(value) => setDeviceIds(value as number[])}
          disabled={!isAdmin}
          multiple
        />
        <SettingsSelect
          label={t('ra.flow.settings.priority.label')}
          options={PRIORITY_OPTIONS}
          value={priority}
          onChange={(value) => setPriority(value as PriorityValue)}
          disabled={!isAdmin}
        />
        <SettingsInput
          label={t('ra.flow.settings.runCount.label')}
          value={runCount}
          onChange={(value) => setRunCount(value)}
          type="number"
          disabled={!isAdmin}
        />
        <SettingsInput
          label={t('ra.flow.settings.runCountInstrumented.label')}
          value={runCountInstrumented}
          onChange={(value) => setRunCountInstrumented(value as number)}
          type="number"
          disabled={!isAdmin}
        />
        <SettingsInput
          label={t('ra.flow.settings.switchFreq.label')}
          value={switchFreq}
          onChange={(value) => setSwitchFreq(value as number)}
          type="number"
          disabled={!isAdmin}
        />
        <SettingsSelect
          label={t('ra.flow.settings.instrumentedRunCondition.label')}
          options={RUN_CONDITION_OPTIONS}
          value={runCondition}
          onChange={(value) => setRunCondition(value as InstrumentedRunConditionValue)}
          disabled={!isAdmin}
        />
        <SettingsCheckBox
          label={t('ra.flow.settings.forceStop.label')}
          value={forceStop}
          onChange={(value) => setForceStop(value)}
          disabled={!isAdmin}
        />
        <SettingsCheckBox
          label={t('ra.flow.settings.clearData.label')}
          value={clearData}
          onChange={(value) => setClearData(value)}
          disabled={!isAdmin}
        />
        <SettingsAutomationInput
          scriptLabel={t('ra.flow.settings.automationScript.label')}
          fileName={automationFileName}
          onFileUpload={(file) => {
            setAutomationFileName(extractFileName(file.name))
            setAutomationFile(file)
          }}
          appId={automationAppId}
          onChangeAppId={(value) => setAutomationAppId(value)}
          optionClass={automationInstrumentOptionClass}
          onChangeClass={(value) => setAutomationInstrumentOptionClass(value)}
        />
        <SettingsAutomationInput
          scriptLabel={t('ra.flow.settings.automationScript.labelSetup')}
          fileName={setupFileName}
          onFileUpload={(file) => {
            setSetupFileName(extractFileName(file.name))
            setSetupFile(file)
          }}
          appId={setupAppId}
          onChangeAppId={(value) => setSetupAppId(value)}
          optionClass={setupInstrumentOptionClass}
          onChangeClass={(value) => setSetupInstrumentOptionClass(value)}
        />
      </div>
    </Modal>
  )
}
