import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { Slice } from 'components/ps-chart/models/Slice'
import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { pipe } from 'utils/pipe'
import {
  linkTreeNodes,
  TreeNode,
} from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { findOrCreateTreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/findOrCreateTreeNode'
import { mergeSameTargetStackChains } from 'components/ps-chart/stores/connections-store/mergeSameTargetStackChains'
import { mergeSameTargetSliceChains } from 'components/ps-chart/stores/connections-store/mergeSameTargetSliceChains'
import { getStackChainsLinks } from 'components/ps-chart/stores/connections-store/getStackChainsLinks'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { TraceAnalyzeStore } from 'components/ps-chart/stores/TraceAnalyzeStore'

/**
 * There definitely can't be a regular backward connection between ancestor to child. But we have cycle slices
 * (such as Android's Choreographer \ iOS lifecycle functions) which have tool suggestion tool for variants
 * of possible execution path. That tool will create link which will cause such backward connection in case
 * when variant path's first slice is child of cycle slice. So we need to simply filter out these connections
 */

const filterOutAncestorCycleSliceReturnChain = (currentStepSliceId: number) => {
  return (chainsRoots: SliceLink[]) => {
    return chainsRoots.filter((link) => link.toSliceId !== currentStepSliceId)
  }
}

export const getLinksTree = (
  selectedSlice: Slice | null,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  sliceById: ReadonlySliceById,
  isFirstSliceFixed = false,
): TreeNode | null => {
  if (selectedSlice == null) {
    return null
  }
  const treeNodeById = new Map<number, TreeNode>()
  const treeRoot = findOrCreateTreeNode(selectedSlice.id, treeNodeById)
  const passedSlices = new Set<number>([selectedSlice.id])

  let curLevelNodes = [treeRoot]
  let curChainStep = 0
  while (curLevelNodes.length > 0) {
    const nextLevelNodes = []
    for (const curTreeNode of curLevelNodes) {
      const curSlice = sliceById.get(curTreeNode.sliceId)!

      const stackChainsLinks = getStackChainsLinks(
        curSlice,
        sliceLinksBySliceId,
        isFirstSliceFixed && curSlice.id === selectedSlice.id,
      )

      const curStackChainLinks = pipe(stackChainsLinks)([
        filterOutAncestorCycleSliceReturnChain(curSlice.id),
        mergeSameTargetSliceChains(sliceById),
        mergeSameTargetStackChains(sliceLinksBySliceId, sliceById),
      ])

      // Add a connection from the current slice with no connection into an ancestor
      if (!curStackChainLinks.length && curChainStep && curSlice.root !== null) {
        const fromTreeNode = findOrCreateTreeNode(curSlice.id, treeNodeById)
        const toTreeNode = findOrCreateTreeNode(curSlice.root.id, treeNodeById)
        linkTreeNodes(fromTreeNode, toTreeNode, ConnectionType.TREE)
      }

      // Add a connection from the current slice with no connection into Cycle ancestor
      if (curSlice.root !== null && TraceAnalyzeStore.isCycleSlice(curSlice.root)) {
        const curSliceHasConnection = curStackChainLinks.filter(
          (link) => link.fromSliceId === curSlice.id,
        ).length
        if (!curSliceHasConnection) {
          const fromTreeNode = findOrCreateTreeNode(curSlice.id, treeNodeById)
          const toTreeNode = findOrCreateTreeNode(curSlice.root.id, treeNodeById)
          linkTreeNodes(fromTreeNode, toTreeNode, ConnectionType.CYCLE)
        }
      }

      for (const stackChainLink of curStackChainLinks) {
        const fromTreeNode = findOrCreateTreeNode(stackChainLink.fromSliceId, treeNodeById)
        const toTreeNode = findOrCreateTreeNode(stackChainLink.toSliceId, treeNodeById)

        linkTreeNodes(fromTreeNode, toTreeNode, stackChainLink.connectionType)

        /** Add a connection from the current slice into an ancestor with a link.
         * If ancestor is CycleSlice we need mark that connection as {@link ConnectionType.CYCLE}
         * to avoid chain recursion with first cycle of execution chain (from exact same CycleSlice):
         * We want Cycles being able to interconnect with each other but at same time it will cause
         * recursion. By marking connection from Cycle Slice's child to Cycle slice we will filter
         * that connection in {@link getMainChainFromTree}
         **/
        if (fromTreeNode !== curTreeNode) {
          const connectionType = TraceAnalyzeStore.isCycleSlice(
            sliceById.get(fromTreeNode.sliceId)!,
          )
            ? ConnectionType.CYCLE
            : ConnectionType.TREE
          linkTreeNodes(curTreeNode, fromTreeNode, connectionType)
        }

        if (!passedSlices.has(toTreeNode.sliceId)) {
          nextLevelNodes.push(toTreeNode)
          passedSlices.add(toTreeNode.sliceId)
        }
      }
    }
    curChainStep++
    curLevelNodes = nextLevelNodes
  }

  return treeRoot
}

export const getLinksTreeSimple = (
  selectedSlice: Slice | null,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  sliceById: ReadonlySliceById,
  cycleSelectedVariantIndexLookup: Map<number, number>,
  cyclePinnedVariantIndexLookup: Map<number, number>,
) => {
  if (selectedSlice == null) {
    return null
  }
  const treeNodeById = new Map<number, TreeNode>()
  const treeRoot = findOrCreateTreeNode(selectedSlice.id, treeNodeById)
  const passedSlices = new Set<number>([selectedSlice.id])

  const nodesStack = [treeRoot]
  while (nodesStack.length > 0) {
    const curNode = nodesStack.pop()!
    const curSlice = sliceById.get(curNode.sliceId)!

    const allLinks = sliceLinksBySliceId.get(curNode.sliceId)
    const usedLinks = (() => {
      if (allLinks == null) {
        return []
      }
      if (TraceAnalyzeStore.isCycleSlice(curSlice)) {
        const cycleSliceIndex = TraceAnalyzeStore.getCycleSliceVariantIndex(
          curSlice.id,
          cycleSelectedVariantIndexLookup,
          cyclePinnedVariantIndexLookup,
        )
        return [allLinks[cycleSliceIndex]]
      }
      return allLinks
    })()

    usedLinks?.forEach((link) => {
      const toNode = findOrCreateTreeNode(link.toSliceId, treeNodeById)
      if (areNodesConnected(curNode, toNode)) {
        return
      }
      linkTreeNodes(curNode, toNode, link.connectionType)
      if (!passedSlices.has(toNode.sliceId)) {
        nodesStack.push(toNode)
        passedSlices.add(toNode.sliceId)
      }
    })
  }

  return treeRoot
}

const areNodesConnected = (nodeA: TreeNode, nodeB: TreeNode) => {
  return (
    nodeA.fromLinks.some((link) => link.toTreeNode === nodeB) ||
    nodeB.fromLinks.some((link) => link.toTreeNode === nodeA)
  )
}
