import { DashboardRunDto } from 'api/__generated__/api-types'
import { RegressionIcon } from 'components/flows/ra/runs/RegressionIcon'
import React from 'react'

interface RunSummaryProps {
  run: DashboardRunDto
}

export const RunSummary = ({ run }: RunSummaryProps) => {
  return (
    <p className="flex items-center space-x-1">
      <RegressionIcon run={run} />
      <span>{new Date(run.dateCreated).toLocaleString()}</span>
      <span>{run.device?.name}</span>
    </p>
  )
}
