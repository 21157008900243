import { makeAutoObservable, runInAction } from 'mobx'
import i18next from 'i18next'

import { GlobalTimelineContent } from 'components/common/models/Segment'
import { calcThreadsPreviewContent } from 'components/ps-chart/utils/calcPreviewContent'
import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { Api } from 'api/Api'
import {
  GetConnectionsDto,
  ChartPageParams,
  TraceSettingsDto,
  MetricsMethodTag,
  NamedLinkDto,
  NamedLinkType,
} from 'api/models'
import { HorizontalStateStore } from 'components/ps-chart/stores/HorizontalStateStore'
import { VerticalStateStore } from 'components/ps-chart/stores/VerticalStateStore'
import { RenderingMeasuringStore } from 'components/ps-chart/stores/RenderingMeasuringStore'
import {
  ConnectionErrorCode,
  getAncestorsConnectionError,
  getConnectedSlicesPositionError,
  getConnectionError,
} from 'components/ps-chart/stores/connections-store/getConnectionError'
import { FlagsStore } from 'components/ps-chart/stores/FlagsStore'
import { Point } from 'components/ps-chart/models/helper-types'
import { SearchState } from 'components/ps-chart/stores/SearchState'
import {
  createNamedLink,
  removeLinksBySourceId,
} from 'components/ps-chart/stores/connections-store/createNamedLink'
import { moveToSlice } from 'components/ps-chart/utils/moveTo'
import { ChartRendererStore } from 'components/ps-chart/stores/ChartRendererStore'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { cluster } from 'components/ps-chart/flame-chart/logic/clustering'
import { Toaster } from 'hooks/useToaster'
import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { VideoTimelineStore } from 'components/ps-chart/stores/VideoTimelineStore'
import { AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'
import { TooltipAnimationSettings } from 'components/tooltip/Tooltip'
import { VideoDataStore } from 'components/ps-chart/stores/VideoDataStore'
import { AnnotationsDataStore } from 'components/ps-chart/stores/AnnotationsDataStore'
import React from 'react'
import { FlagsDataStore } from 'components/ps-chart/stores/FlagsDataStore'
import { ReadonlySliceById, TraceDataState } from 'components/ps-chart/stores/TraceDataStore'
import { TraceAnalyzeStore } from 'components/ps-chart/stores/TraceAnalyzeStore'
import { MeasurementPoints } from 'components/ps-chart/flame-chart/RenderEngine'
import { Thread } from 'components/ps-chart/models/Thread'
import { Pointer } from 'components/ps-chart/flame-chart/Pointer'
import { isAncestor } from 'components/ps-chart/stores/connections-store/LinksTree/isAncestor'
import { ChartDataStore } from 'components/ps-chart/stores/ChartDataStore'
import { connectionMetrics } from 'utils/ConnectionsMetrics'
import { SearchStore } from 'components/ps-chart/actions-panel/SearchStore'
import { PsChartUIState } from 'components/ps-chart/PsChartUIState'
import { Slice, SliceStats } from './models/Slice'
import { PsChartSettings } from './models/settings'

enum DataLoadingState {
  EMPTY,
  LOADING,
  LOADED,
}

export interface Error {
  id: number
  msg: string
}

export interface Tooltip {
  title: string
  point: Point
  visible?: boolean
  settings?: TooltipAnimationSettings
}

export interface PsChartFeatures {
  flags: boolean
  annotations: AnnotationsFeatureState
  measurementTool: boolean
}

export interface AnnotationsFeatureState {
  enabled: boolean
  draggable: boolean
  clickable: boolean
  flowAccess: boolean
}

export const psChartStoreContext = React.createContext<PsChartStore | null>(null)

export class PsChartStore {
  readonly searchStore: SearchStore
  readonly uiState: PsChartUIState
  readonly chartSettings: PsChartSettings
  readonly chartFeatures: PsChartFeatures = {
    flags: true,
    measurementTool: true,
    annotations: { enabled: true, draggable: false, clickable: true, flowAccess: true },
  }

  private dataLoadingState = DataLoadingState.EMPTY

  readonly traceDataState: TraceDataState
  readonly traceAnalyzeStore: TraceAnalyzeStore
  readonly hState: HorizontalStateStore
  readonly vState: VerticalStateStore

  readonly flagsStore: FlagsStore

  readonly annotationsStore: AnnotationsStore

  isLinkModeActive = false

  isMeasurementModeActive = false

  /**
   * isMeasuredSlicesShow: prop which will override rules to show measured slices. Required for the Live Demo functionality.
   */
  isMeasuredSlicesShow = false

  /**
   * shouldKeepActiveSelection: prop which will prevent deselect of current slice (prevent {@link FlameChartRenderer.onMouseUp})
   */
  shouldKeepActiveSelection = false

  linkModeSliceId: number | null = null

  hoveredThreadId: number | null = null
  hoveredSliceId: number | null = null

  private readonly api: Api

  readonly chartPageParams: Required<ChartPageParams>

  tooltip: Tooltip | null = null

  readonly renderingMeasuring: RenderingMeasuringStore = new RenderingMeasuringStore()

  readonly searchState: SearchState

  readonly rendererStore: ChartRendererStore

  private isDimDisconnectedSlicesEnabled = false

  isTransparentConnectionEnabled = false

  private readonly toaster: Toaster

  isVideoPreviewInGlobalTimelineEnabled = false

  /**
   * Temporarily. Added to weaken connections constraints for a static page (Eugeny Malutin's work)
   */
  readonly isStaticPageMode: boolean

  readonly videoPlayerStore: VideoPlayerStore

  readonly videoTimelineStore: VideoTimelineStore

  private readonly videoDataStore: VideoDataStore
  private readonly annotationsDataStore: AnnotationsDataStore
  private readonly chartDataStore: ChartDataStore
  measurementPoints: MeasurementPoints | null = null

  isDisposed = false

  isEnabledListeners = true

  isAddSliceConnectionsEnabled = false

  constructor(
    chartDataStore: ChartDataStore,
    chartSettings: PsChartSettings,
    api: Api,
    chartPageParams: ChartPageParams,
    toaster: Toaster,
    threadsDataState: TraceDataState,
    hStateStore: HorizontalStateStore,
    videoDataStore: VideoDataStore,
    annotationsDataStore: AnnotationsDataStore,
    flagsDataStore: FlagsDataStore,
    isVideoModeEnabled?: boolean,
    isStaticPageMode?: boolean,
  ) {
    this.chartDataStore = chartDataStore
    makeAutoObservable<
      PsChartStore,
      'api' | 'chartDataStore' | 'chartPageParams' | 'videoDataStore' | 'toaster'
    >(this, {
      chartDataStore: false,
      chartSettings: false,
      toaster: false,
      videoDataStore: false,
      api: false,
      chartPageParams: false,
      sliceById: false,
    })
    this.api = api
    this.chartPageParams = chartPageParams
    this.toaster = toaster
    this.isVideoPreviewInGlobalTimelineEnabled = isVideoModeEnabled || false
    this.videoDataStore = videoDataStore
    this.annotationsDataStore = annotationsDataStore
    this.chartSettings = chartSettings
    this.isStaticPageMode = isStaticPageMode ?? false
    this.traceDataState = threadsDataState
    this.traceAnalyzeStore = new TraceAnalyzeStore(
      this.api,
      this.toaster,
      this.traceDataState,
      this.chartSettings.renderEngine.threads,
      this.chartPageParams,
    )
    this.hState = hStateStore
    this.vState = new VerticalStateStore(this.traceAnalyzeStore, chartSettings)
    this.flagsStore = new FlagsStore(
      this.api,
      chartPageParams,
      flagsDataStore,
      this.chartSettings.renderEngine.flags,
      this.chartFeatures,
    )
    this.searchState = new SearchState()
    this.searchStore = new SearchStore(this.searchState)
    this.uiState = new PsChartUIState()
    this.rendererStore = new ChartRendererStore(
      this.traceAnalyzeStore,
      this.hState,
      this.vState,
      this.chartSettings,
    )
    this.videoPlayerStore = new VideoPlayerStore({
      threadsStore: this.traceDataState,
      hState: this.hState,
      videoDataStore,
    })
    this.annotationsStore = new AnnotationsStore(
      this.api,
      chartPageParams,
      this.videoPlayerStore,
      this.chartFeatures.annotations,
      this.annotationsDataStore,
      this.chartSettings.renderEngine.annotation,
    )
    this.videoTimelineStore = new VideoTimelineStore(toaster)
  }

  setIsAddSliceConnectionsEnabled(value: boolean) {
    this.isAddSliceConnectionsEnabled = value
  }

  get sliceById(): ReadonlySliceById {
    return this.traceDataState.sliceById
  }

  get hoveredThread(): Thread | null {
    if (this.hoveredThreadId) {
      return this.traceDataState.threadsById.get(this.hoveredThreadId)!
    }
    return null
  }

  get hoveredThreadY(): number {
    if (this.hoveredThreadId) {
      return this.getThreadY(this.hoveredThreadId)
    }
    return 0
  }

  setIsVideoModeEnabled(value: boolean) {
    this.isVideoPreviewInGlobalTimelineEnabled = value
  }

  setHoveredThreadId = (threadId: null | number) => {
    this.hoveredThreadId = threadId
  }

  setHoveredSliceId = (sliceId: number | null) => {
    this.hoveredSliceId = sliceId
  }

  reloadConnections(namedLinks: NamedLinkDto[] | null) {
    this.traceAnalyzeStore.sliceLinksBySliceId.clear()
    const parsingStart = performance.now()
    this.fillAutoConnections()
    if (namedLinks) {
      this.setNamedLinks(namedLinks)
    }
    connectionMetrics.addConnectionsHandlingTime(
      performance.now() - parsingStart,
      MetricsMethodTag.frontendConnections,
      'PsChartStore::fillAutoConnections/setNamedLinks',
    )
  }

  loadConnections(
    isBeConnectionsEnabled: boolean,
    namedLinks: NamedLinkDto[] | null,
    choreographerPaths: TraceSettingsDto | null,
    beConnectionsData: GetConnectionsDto | null,
  ) {
    console.info('#CM PsChartStore->loadConnections [performance.now]', window.performance.now())
    if (isBeConnectionsEnabled) {
      if (beConnectionsData != null) {
        const parsingStart = performance.now()
        this.setSliceLinksFromBeConnections(beConnectionsData)
        connectionMetrics.addConnectionsHandlingTime(
          performance.now() - parsingStart,
          MetricsMethodTag.backendConnections,
          'PsChartStore::setSliceLinksFromBeConnections',
        )
      }
    } else {
      const parsingStart = performance.now()
      this.fillAutoConnections()
      if (namedLinks) {
        this.setNamedLinks(namedLinks)
      }
      connectionMetrics.addConnectionsHandlingTime(
        performance.now() - parsingStart,
        MetricsMethodTag.frontendConnections,
        'PsChartStore::fillAutoConnections/setNamedLinks',
      )
    }
    if (choreographerPaths) {
      this.traceAnalyzeStore.pinCycleVariants(choreographerPaths)
    }
  }

  setSliceLinksFromBeConnections(beConnectionsData: GetConnectionsDto) {
    this.traceAnalyzeStore.sliceLinksBySliceId.clear()
    const getConnectionTypeData = (
      fromSliceId: number,
      toSliceId: number,
    ): [ConnectionType, string | undefined] => {
      if (isAncestor(fromSliceId, toSliceId, this.sliceById)) {
        return [ConnectionType.TREE, undefined]
      }

      const editableLinkData = beConnectionsData.editableLinksConnections[String(fromSliceId)]
      if (editableLinkData != null) {
        for (const connectionMeta of editableLinkData) {
          if (connectionMeta.sliceId === toSliceId) {
            return [ConnectionType.MANUAL, connectionMeta.airtableLinkId]
          }
        }
      }

      return [ConnectionType.CLOSURE, undefined]
    }

    Object.keys(beConnectionsData.connections).forEach((key) => {
      const fromSliceId = Number(key)
      const toSliceIds = beConnectionsData.connections[key]

      this.traceAnalyzeStore.sliceLinksBySliceId.set(
        fromSliceId,
        // @ts-expect-error FIXME: 'toSliceIds' is possibly 'undefined'. (tsserver 18048)
        toSliceIds.map((toSliceId) => {
          const [connectionType, sourceId] = getConnectionTypeData(fromSliceId, toSliceId)
          return {
            fromSliceId,
            toSliceId,
            connectionType,
            isEditable: sourceId != null,
            sourceId,
          }
        }),
      )
    })
  }

  dispose() {
    this.videoPlayerStore.dispose()
    this.isDisposed = true
  }

  get shouldDimDisconnectedSlices() {
    return this.traceAnalyzeStore.chainExists && this.isDimDisconnectedSlicesEnabled
  }

  get isLoading() {
    return this.dataLoadingState === DataLoadingState.LOADING
  }

  get isLoaded() {
    return this.dataLoadingState === DataLoadingState.LOADED
  }

  get isEmpty() {
    return this.dataLoadingState === DataLoadingState.EMPTY
  }

  setIsEmpty() {
    this.dataLoadingState = DataLoadingState.EMPTY
  }

  setIsLoading() {
    this.dataLoadingState = DataLoadingState.LOADING
  }

  setIsLoaded() {
    this.dataLoadingState = DataLoadingState.LOADED
  }

  setTooltip(tooltip: Tooltip | null) {
    this.tooltip = tooltip
  }

  get globalTimelineContent(): GlobalTimelineContent {
    return calcThreadsPreviewContent(
      this.sortedThreads,
      HorizontalStateStore.timePerPx(this.hState.xWidthTotal, this.hState.width),
      this.chartSettings.clusteringMinSliceSizePx,
      this.chartSettings.clusteringStickSizePx,
    )
  }

  getXByTime = (time: number) => {
    const diff = time - this.hState.xStart
    return diff / this.hState.timePerPx
  }

  getTimeByX = (x: number) => {
    return this.hState.xStart + Math.round(x * this.hState.timePerPx)
  }

  moveTo(x: number, y: number) {
    const runInActionMoveTo = () => {
      this.hState.setXStartAndZoom(x)
      this.vState.setYStart(y)
    }
    runInAction(runInActionMoveTo)
  }

  private reset() {
    this.setIsEmpty()
    this.setSelectedSlice(null)
    this.traceAnalyzeStore.sliceLinksBySliceId.clear()
  }

  setSelectedSlice(slice: Slice | null) {
    console.info('setSelectedSlice->slice', slice)
    this.traceAnalyzeStore.setSelectedSlice(slice)

    if (slice) {
      this.flagsStore.clearSelected()
    }
  }

  /**
   * Get thread based on last mouse positions and slices from selected range (measurementPoints)
   * and calculate stats for slices (number occurrences, function average and total length)
   */
  get measurementSlices(): SliceStats[] {
    const shouldShowSlices = this.isMeasuredSlicesShow || !this.isMeasurementModeActive

    if (this.measurementPoints?.endTime === undefined || !shouldShowSlices) {
      return []
    }

    const { isMain, startTime, endTime, y: startY } = this.measurementPoints

    const threads = isMain
      ? this.traceAnalyzeStore.mainThreads
      : this.traceAnalyzeStore.pinnedThreads

    const threadsTopBottomMap = isMain
      ? this.vState.mainThreadsTopBottomMap
      : this.vState.pinnedThreadsTopBottomMap

    const thread = threads.find((curThread) => {
      const [curThreadTop, curThreadBottom] = threadsTopBottomMap.get(curThread.id)!
      return startY >= curThreadTop && startY <= curThreadBottom
    })

    const slices: SliceStats[] = []

    thread?.functionStatsByName.forEach((stats, title) => {
      const filtered = stats.filter((stat) => stat.start < endTime && stat.end > startTime)
      const occurrences = filtered.length

      if (occurrences) {
        const total = filtered
          .map((stat) => stat.duration)
          .reduce((duration, reduced) => reduced + duration)
        const average = total / occurrences
        slices.push({ title, total, average, occurrences })
      }
    })

    return slices.sort((prev, next) => next.occurrences - prev.occurrences)
  }

  enableLinkMode(sliceId: number) {
    if (this.isAddSliceConnectionsEnabled) {
      this.isLinkModeActive = true
      this.linkModeSliceId = sliceId
    }
  }

  disableLinkMode() {
    this.isLinkModeActive = false
    this.linkModeSliceId = null
  }

  enableMeasurementMode() {
    this.isMeasurementModeActive = true
  }

  disableMeasurementMode() {
    this.isMeasurementModeActive = false
  }

  lockSelection() {
    this.shouldKeepActiveSelection = true
  }

  unlockSelection() {
    this.shouldKeepActiveSelection = false
  }

  connectSlices(fromSlice: Slice, toSlice: Slice): Promise<void> {
    const conErrorMsg = getConnectionError(
      fromSlice,
      toSlice,
      this.sliceById,
      this.traceAnalyzeStore.sliceLinksBySliceId,
      NamedLinkType.SYNC,
    )
    if (conErrorMsg != null) {
      return Promise.reject(i18next.t(conErrorMsg))
    }

    const newNamedLink = createNamedLink(fromSlice, toSlice, true)

    const previousMainChain = this.traceAnalyzeStore.mainChainSlices

    if (this.traceAnalyzeStore.isBeConnectionsEnabled) {
      const newSliceLinks = this.traceAnalyzeStore.sliceLinksBySliceId.get(fromSlice.id) ?? []
      const newLink: SliceLink = {
        sourceId: newNamedLink.id,
        fromSliceId: fromSlice.id,
        toSliceId: toSlice.id,
        connectionType: ConnectionType.MANUAL,
        isEditable: false,
      }
      this.traceAnalyzeStore.sliceLinksBySliceId.set(fromSlice.id, [...newSliceLinks, newLink])
    } else {
      this.traceAnalyzeStore.fillNamedLinks([newNamedLink])
    }

    const currentMainChain = this.traceAnalyzeStore.mainChainSlices

    this.setShouldShowAllPathsFilter(previousMainChain, currentMainChain)

    const isCreatedLinksMoved = () => {
      const existedLinks = this.traceAnalyzeStore.sliceLinksBySliceId.get(fromSlice.id) ?? []
      const requestedLink = existedLinks.find((link) => link.toSliceId === toSlice.id)
      return requestedLink == null
    }

    if (isCreatedLinksMoved()) {
      this.toaster.info('psChart.connections.closerLinkWithSameFunc')
    }

    this.disableLinkMode()
    moveToSlice(toSlice.id, this)

    return this.api
      .postNamedLink(this.chartPageParams.projectUrlName, {
        fromName: newNamedLink.fromName,
        toName: newNamedLink.toName,
        type: newNamedLink.type,
        isEditable: true,
      })
      .then((namedLinkDto: NamedLinkDto) => {
        if (this.traceAnalyzeStore.isBeConnectionsEnabled) {
          this.refetchBeConnections().then(() => {
            if (isCreatedLinksMoved()) {
              this.toaster.info('psChart.connections.closerLinkWithSameFunc')
            }
          })
        } else {
          runInAction(() => {
            removeLinksBySourceId(this.traceAnalyzeStore.sliceLinksBySliceId, newNamedLink.id)
            this.traceAnalyzeStore.fillNamedLinks([namedLinkDto])
          })
        }
      })
      .catch((reason) => {
        if (this.traceAnalyzeStore.isBeConnectionsEnabled) {
          const allSliceLinks = this.traceAnalyzeStore.sliceLinksBySliceId.get(fromSlice.id) ?? []
          const preExistedLinks = allSliceLinks.filter((link) => link.sourceId !== newNamedLink.id)
          this.traceAnalyzeStore.sliceLinksBySliceId.set(fromSlice.id, preExistedLinks)
        } else {
          runInAction(() => {
            removeLinksBySourceId(this.traceAnalyzeStore.sliceLinksBySliceId, newNamedLink.id)
          })
        }
        return Promise.reject(reason)
      })
  }

  private setShouldShowAllPathsFilter(previousMainChain: Slice[], currentMainChain: Slice[]) {
    if (!this.traceAnalyzeStore.shouldShowAllPaths) {
      const previous = previousMainChain.map(({ id }) => id).join(':')
      const current = currentMainChain.map(({ id }) => id).join(':')

      if (current.includes(previous) && current !== previous) {
        return null
      }

      this.toggleShouldShowAllPaths()

      if (current !== previous) {
        this.toaster.info('psChart.connections.mepChanged')
      } else {
        this.toaster.info('psChart.connections.newLinkOutsideMep')
      }
    }
  }

  disconnectSlice(sliceLink: SliceLink): Promise<void> {
    const initFromSliceId = sliceLink.fromSliceId
    const { sourceId } = sliceLink
    if (sourceId == null) {
      throw new Error("This links can't be deleted because the sourceId is not defined.")
    }

    const removedLinks = removeLinksBySourceId(this.traceAnalyzeStore.sliceLinksBySliceId, sourceId)

    const namedLinkDto = this.traceAnalyzeStore.namedLinksByLinkId.get(sourceId)
    if (namedLinkDto != null) {
      this.traceAnalyzeStore.namedLinksByLinkId.delete(sourceId)
    }

    moveToSlice(initFromSliceId, this)

    if (this.isLinkModeActive) {
      this.linkModeSliceId = initFromSliceId
    }
    return this.api
      .deleteNamedLink(this.chartPageParams.projectUrlName, sourceId)
      .then(() => {
        if (this.traceAnalyzeStore.isBeConnectionsEnabled) {
          this.refetchBeConnections()
        }
      })
      .catch((reason) => {
        runInAction(() => {
          if (namedLinkDto != null) {
            this.traceAnalyzeStore.fillNamedLinks([namedLinkDto])
          } else {
            removedLinks.forEach((links, fromSliceId) => {
              const existedLinks = this.traceAnalyzeStore.sliceLinksBySliceId.get(fromSliceId)
              if (existedLinks != null) {
                this.traceAnalyzeStore.sliceLinksBySliceId.set(fromSliceId, [
                  ...existedLinks,
                  ...links,
                ])
              }
            })
          }
        })
        return Promise.reject(reason)
      })
  }

  private refetchBeConnections() {
    return this.chartDataStore.fetchTraceConnections().then(() => {
      if (this.chartDataStore.beConnectionsData != null) {
        this.setSliceLinksFromBeConnections(this.chartDataStore.beConnectionsData)
      }
    })
  }

  toggleShouldShowAllPaths() {
    this.traceAnalyzeStore.shouldShowAllPaths = !this.traceAnalyzeStore.shouldShowAllPaths
  }

  toggleIsDimDisconnectedSlicesEnabled() {
    this.isDimDisconnectedSlicesEnabled = !this.isDimDisconnectedSlicesEnabled
  }

  toggleRenderTypeMode() {
    this.rendererStore.switchRenderTypeMode()
  }

  enableTransparentConnection() {
    this.isTransparentConnectionEnabled = true
  }

  disableTransparentConnection() {
    this.isTransparentConnectionEnabled = false
  }

  get globalTimelineSearchHighlightsContent(): GlobalTimelineContent {
    const threadLineIndexById = new Map<number, number>()
    this.sortedThreads.forEach((thread, index) => {
      threadLineIndexById.set(thread.id, index)
    })

    const result: GlobalTimelineContent = []

    if (this.searchState.searchResults.length > 0) {
      const rows: Array<Slice>[] = []
      this.searchState.searchResults.forEach((sliceId) => {
        const slice = this.sliceById.get(sliceId)!
        const threadLineIndex = threadLineIndexById.get(slice.threadId)!
        const row = rows[threadLineIndex]
        if (row) {
          row.push(slice)
        } else {
          rows[threadLineIndex] = [slice]
        }
      })

      rows.forEach((row, rowIndex) => {
        const sortedRowSlices = row.sort((a, b) => a.start - b.start)
        result[rowIndex] = cluster(
          sortedRowSlices,
          this.rendererStore.minSliceSize,
          this.rendererStore.stickSize,
          'red',
        )
      })
    }

    return result
  }

  get sortedThreads() {
    return this.traceAnalyzeStore.favThreads.concat(this.traceAnalyzeStore.mainThreads)
  }

  selectFlag(id: number, cid: number | undefined) {
    this.flagsStore.select(id, cid)
  }

  /*
   * It's public only for tests in executionPathFilter.test.ts
   */
  setNamedLinks(namedLinks: NamedLinkDto[]) {
    if (namedLinks.length > 0) {
      this.traceAnalyzeStore.fillNamedLinks(namedLinks)
    }
  }

  /**
   * fillAutoConnections: check for auto links looking for slice closure id.
   * Currently, fillAutoConnections doesn't check if there were any connections before it was called,
   * so it should be called before any manipulation with sliceLinksBySliceId
   */
  private fillAutoConnections() {
    const errorsTable = new Map<ConnectionErrorCode, number>()
    for (const thread of this.traceDataState.threads) {
      for (let level = 0; level <= thread.maxLevel; level++) {
        const slices = thread.slicesByLevel.get(level) ?? []
        for (let index = 0; index < slices.length; index++) {
          const slice = slices[index]
          if (slice.closureId == null) {
            continue
          }
          const toSlice = this.sliceById.get(slice.closureId)!
          const conErrorMsg =
            getConnectedSlicesPositionError(slice, toSlice) ||
            getAncestorsConnectionError(slice, toSlice)
          if (!this.isStaticPageMode && conErrorMsg != null) {
            if (!errorsTable.has(conErrorMsg)) {
              errorsTable.set(conErrorMsg, 0)
            }
            errorsTable.set(conErrorMsg, errorsTable.get(conErrorMsg)! + 1)
            continue
          }
          this.traceAnalyzeStore.sliceLinksBySliceId.set(slice.id, [
            {
              fromSliceId: slice.id,
              toSliceId: slice.closureId,
              connectionType: ConnectionType.CLOSURE,
              isEditable: false,
            },
          ])
        }
      }
    }
    for (const [errorCode, count] of errorsTable.entries()) {
      console.warn(
        `${count} auto-connections have been filtered out. ErrorMsg: ${i18next.t(errorCode)}`,
      )
    }
  }

  setChartFeatures(features: PsChartFeatures) {
    this.chartFeatures.flags = features.flags
    this.chartFeatures.measurementTool = features.measurementTool
    this.chartFeatures.annotations.enabled = features.annotations.enabled
    this.chartFeatures.annotations.draggable = features.annotations.draggable
    this.chartFeatures.annotations.flowAccess = features.annotations.flowAccess
  }

  setIsEnabledListeners = (isEnabled: boolean) => {
    this.isEnabledListeners = isEnabled
  }

  getThreadY = (threadId: number): number => {
    const isPinned = this.traceAnalyzeStore.pinnedIdsSet.has(threadId)
    const threadsTopMap = isPinned ? this.vState.pinnedThreadsTopMap : this.vState.mainThreadsTopMap

    const threadTopPos = threadsTopMap.get(threadId)
    if (threadTopPos == null) {
      throw new Error(`The thread: ${threadId} was not found in the threadTopPos!`)
    }

    return threadTopPos
  }

  getElementsInChartByPointer(pointer: Pointer): { thread?: Thread; slice?: Slice } {
    const result: { thread?: Thread; slice?: Slice } = {}
    const point = pointer.getRelativePoint()
    const isMain = pointer.isMain()
    const isPinned = !isMain && pointer.isPinned()
    if (point == null || !(isMain || isPinned)) {
      return result
    }

    const threads = isMain
      ? this.traceAnalyzeStore.mainThreads
      : this.traceAnalyzeStore.pinnedThreads

    const time = point.x * this.hState.timePerPx + this.hState.xStart
    const posY = isMain ? this.vState.yStart : this.vState.yStartPinned

    const y = posY + point.y
    const threadsTopBottomMap = !isPinned
      ? this.vState.mainThreadsTopBottomMap
      : this.vState.pinnedThreadsTopBottomMap

    result.thread = threads.find((curThread) => {
      const [curThreadTop, curThreadBottom] = threadsTopBottomMap.get(curThread.id)!
      return y >= curThreadTop && y <= curThreadBottom
    })
    if (result.thread) {
      const [threadTop] = threadsTopBottomMap.get(result.thread.id)!
      const threadLevelUnderPointer = Math.floor(
        (y - threadTop - this.chartSettings.renderEngine.threads.topPadding) /
          this.chartSettings.renderEngine.threads.blockHeight,
      )
      const sliceVariations = result.thread.slicesByLevel.get(threadLevelUnderPointer)
      if (sliceVariations !== undefined) {
        result.slice = sliceVariations.find((sliceVariant) => {
          return sliceVariant.start <= time && time <= sliceVariant.end
        })
      }
    }
    return result
  }

  getSliceY = (slice: Slice) => {
    const threadTopY = this.getThreadY(slice.threadId)
    const isPinned = this.traceAnalyzeStore.pinnedIdsSet.has(slice.threadId)

    const sliceTopY =
      (isPinned ? 0 : this.vState.yEndPinned) +
      this.chartSettings.renderEngine.threads.topPadding +
      threadTopY +
      slice.level * this.chartSettings.renderEngine.threads.blockHeight

    return sliceTopY - this.vState.yStart
  }

  setMeasurementPoints = (measurementPoints: MeasurementPoints) => {
    this.measurementPoints = measurementPoints
  }

  setIsMeasuredSlicesShow = (isMeasuredSlicesShow: boolean) => {
    this.isMeasuredSlicesShow = isMeasuredSlicesShow
  }
}
