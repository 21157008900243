import React from 'react'
import { DashboardRunDto } from 'api/__generated__/api-types'
import {
  getRegressionStatus,
  isRunFailed,
  prettifyDate,
  RegressionStatus,
} from 'components/flows/ra/runs/runUtils'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { RunError } from 'components/flows/ra/runs/details/RunError'

interface HeaderProps {
  run: DashboardRunDto
}

export const Header = ({ run }: HeaderProps) => {
  const { t } = useTranslation()
  const status = getRegressionStatus(run)
  const isFailed = isRunFailed(run)
  return (
    <div>
      <p className="space-x-1">
        <span>{t('ra.flow.run.id')}</span>
        <span>{run.id}</span>
      </p>
      {!isFailed ? (
        <p className="space-x-1">
          <span>{t('ra.flow.run.processed')}</span>
          <span>{prettifyDate(run.dateFinished)}</span>
        </p>
      ) : (
        <>
          <RunError run={run} />
        </>
      )}
      {!isFailed && (
        <p className="space-x-1">
          <span>{t('ra.flow.run.regression')}</span>
          <span
            className={classNames(
              status === RegressionStatus.NOT_DETECTED
                ? 'text-white'
                : status === RegressionStatus.SLOWDOWN
                ? 'text-state-bad'
                : 'text-state-good',
            )}
          >
            {run.results.comparison.regressionDetected
              ? t('ra.flow.run.detected')
              : t('ra.flow.run.notDetected')}
          </span>
        </p>
      )}
    </div>
  )
}
