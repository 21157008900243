import { OBJECT_ID_EMPTY_VALUE, Slice } from 'components/ps-chart/models/Slice'
import { getClusterSize, getFullSize, getSliceSize } from 'components/ps-chart/utils/slice'

/**
 * <p>Clustering helps to avoid thousands of slices to renderer in favor of rendering performance.
 * And also it helps to visualise small single slices/clusters extending its size if they're quite far from neighbours.</p>
 *
 * <ul>Algorithm:
 * <li>We walk through all the slices from {@link slicesArray}.</li>
 * <li>If we encounter quite a big slice more than {@link minVisibleSliceSize}, we draw it as it is.</li>
 * <li>If we encounter a small slice less than {@link minVisibleSliceSize}, we add it to cluster.</li>
 * <li>If we encounter a small slice, and it's not far from current cluster (less than {@link stickSize}),
 * we join it to cluster. Otherwise, we push current cluster if it's big enough and create a new cluster.</li>
 * <li>If pushed cluster is quite small and no other slices nearby,
 * we extend it's size to {@link minVisibleSliceSize}</li>
 * </ul>
 * @param slicesArray slices to cluster
 * @param minVisibleSliceSize minimal visible slice size in nanos
 * @param stickSize time range to cluster small slices nearby
 * @param color clustered slice color
 */
export function cluster(
  slicesArray: ReadonlyArray<Slice>,
  minVisibleSliceSize: number,
  stickSize: number,
  color: string,
): Slice[] {
  const result: Slice[] = []

  let currentCluster: Slice[] = []

  for (const slice of slicesArray) {
    if (getSliceSize(slice) >= minVisibleSliceSize) {
      // big enough to be an own slice
      pushCurrentClusterToResult(slice.start)
      result.push(slice)
    } else {
      if (currentCluster.length > 0) {
        const currentSize = getFullSize(currentCluster)
        const distance = slice.start - currentSize.end
        if (distance > stickSize) {
          pushCurrentClusterToResult(slice.start) // far from current => push current and set new current
        }
      }
      currentCluster.push(slice) // small and current is empty
    }
  }
  const nextSliceStartTime =
    slicesArray.length > 0
      ? slicesArray[slicesArray.length - 1].end + minVisibleSliceSize + 1
      : Number.MAX_SAFE_INTEGER - minVisibleSliceSize - 2
  pushCurrentClusterToResult(nextSliceStartTime)

  return result

  function pushCurrentClusterToResult(nextSliceStart: number) {
    if (currentCluster.length > 0) {
      const currentSize = getClusterSize(currentCluster, minVisibleSliceSize)
      if (currentSize.end - currentSize.start > minVisibleSliceSize) {
        // Current cluster is big enough to be rendered
        const clusteredSlice: Slice = createClusterSlice(
          currentSize.start,
          currentSize.end,
          currentCluster[0].threadId,
        )
        result.push(clusteredSlice)
      } else {
        /* Current cluster is small. Let's check if next slice is quite far to render it
        extended to {@link minVisibleSliceSize}
         */
        const nextSliceIsFarEnough = nextSliceStart - currentSize.end >= minVisibleSliceSize + 1
        if (nextSliceIsFarEnough) {
          if (currentCluster.length === 1) {
            // If only one slice in cluster no need to change its color
            const slice = { ...currentCluster[0] }
            slice.end = slice.start + minVisibleSliceSize
            slice.children = []
            result.push(slice)
          } else {
            const clusteredSlice: Slice = createClusterSlice(
              currentSize.start,
              currentSize.start + minVisibleSliceSize,
              currentCluster[0].threadId,
            )
            result.push(clusteredSlice)
          }
        }
      }
      currentCluster = []
    }

    function createClusterSlice(start: number, end: number, threadId: number): Slice {
      return {
        start: start,
        end: end,
        title: String(currentCluster.length),
        color: color,
        level: currentCluster[0].level,
        id: 0,
        objectId: OBJECT_ID_EMPTY_VALUE,
        runnableId: OBJECT_ID_EMPTY_VALUE,
        isCluster: true,
        isNetworkRequest: false,
        isLoggedEvent: false,
        closureId: null,
        threadId: threadId,
        rootPositionIndex: -1,
        args: [],
        parent: null,
        root: null,
      }
    }
  }
}
