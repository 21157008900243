import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'

export const sortLinksByTargetSliceLevel = (
  chainsRoots: SliceLink[],
  sliceById: ReadonlySliceById,
) => {
  // Array.prototype.sort mutates original array which ruins original sorting of stack
  return [...chainsRoots].sort((chainRootA, chainRootB) => {
    const targetSliceA = sliceById.get(chainRootA.toSliceId)!
    const targetSliceB = sliceById.get(chainRootB.toSliceId)!

    return targetSliceB.level - targetSliceA.level
  })
}

export const mergeSameTargetStackChains =
  (sliceLinksById: Map<number, ReadonlyArray<SliceLink>>, sliceById: ReadonlySliceById) =>
  (chainsRoots: SliceLink[]) => {
    const youngTargetFirstChainsRoots = sortLinksByTargetSliceLevel(chainsRoots, sliceById)
    const occupiedSlices = new Set<number>()
    const filteredChainsRoots = new Set<SliceLink>()
    for (const curChainRoot of youngTargetFirstChainsRoots) {
      if (occupiedSlices.has(curChainRoot.toSliceId)) {
        continue
      }
      filteredChainsRoots.add(curChainRoot)
      let curTargetSlice = sliceById.get(curChainRoot.toSliceId)!
      while (true) {
        occupiedSlices.add(curTargetSlice.id)
        if (curTargetSlice.parent == null) {
          break
        }
        curTargetSlice = curTargetSlice.parent!
      }
    }

    return chainsRoots.filter((root) => filteredChainsRoots.has(root))
  }

export const getAncestorsOccupiedSliceIds = (
  sliceById: ReadonlySliceById,
  stackChainLinks: SliceLink[],
) => {
  const deepTargetFirstStackLinks = sortLinksByTargetSliceLevel(stackChainLinks, sliceById)
  const occupiedSlices = new Set<number>()
  for (const curStackLink of deepTargetFirstStackLinks) {
    if (occupiedSlices.has(curStackLink.toSliceId)) {
      continue
    }
    let curTargetSlice = sliceById.get(curStackLink.toSliceId)!
    while (true) {
      occupiedSlices.add(curTargetSlice.id)
      if (curTargetSlice.parent == null) {
        break
      }
      curTargetSlice = curTargetSlice.parent!
    }
  }

  return occupiedSlices
}
