export const getPart = (nanos: number, type: 's' | 'ms' | 'µs' | 'ns') => {
  const options = {
    s: { min: 0, max: -9 },
    ms: { min: -9, max: -6 },
    µs: { min: -6, max: -3 },
    ns: { min: -3, max: undefined },
  }
  const parsedTime = String(nanos).slice(options[type].min, options[type].max)
  return parsedTime ? parseInt(parsedTime) : 0
}

export const nanoToString = (nanos: number): string => {
  return `${getPart(nanos, 's')}s ${getPart(nanos, 'ms')}ms ${getPart(nanos, 'µs')}µs ${getPart(
    nanos,
    'ns',
  )}ns`
}

export const nanoToMeasurementString = (nanos: number): string => {
  const units = ['ns', 'µs', 'ms', 's']
  let unitIndex = 0
  let value = nanos

  while (value > 1000 && unitIndex < units.length - 1) {
    value = Math.round((value / 1000) * 100) / 100
    unitIndex++
  }

  if (unitIndex !== units.length - 1 && value > 20) {
    value = Math.round(value)
  }

  return value + units[unitIndex]
}
