import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import { Layout } from 'components/Layout'
import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { FlowCard } from 'components/flows/FlowCard'
import { Empty } from 'components/Empty'
import { getFlowName } from 'utils/getFlowName'
import { get } from 'utils/get'

import BittenBiscuitsSVG from 'assets/img/bitten-biscuits.svg'

import {
  useFlowsQuery,
  useProjectQuery,
  useUnassignedTracesQuery,
  useUserQuery,
} from 'hooks/useApiQuery'
import { PATH_UNASSIGNED } from 'pages/UnassignedPage'
import { FlowFormModal } from 'components/flows/FlowFormModal'
import { ProjectDto, ProjectRoleApi, TeamRoleApi } from 'api/models'
import { ProjectSettings } from 'components/projects/ProjectSettings'
import { PATH_PROJECT_CONTRIBUTORS } from 'pages/ProjectContributorsPage'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { Popover } from 'components/dropdowns/Popover'
import { FEATURE_FLAGS, useFeatureFlag, useIsReadOnlyProject } from 'utils/feature-flags'
import { useIoGuidesRedirect } from 'components/guide-io/useIoGuidesRedirect'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { ProjectFlowsPageBreadcrumbs } from 'components/breadcrumbs/ProjectFlowsPageBreadcrumbs'

export const PATH_FLOWS_END = 'flows'
export const PATH_PROJECTS = '/projects/'
export const PATH_FLOWS_START = `${PATH_PROJECTS}:projectUrlName/`
export const PATH_FLOWS = PATH_FLOWS_START + PATH_FLOWS_END

type FilterType = {
  name: string
  by: string
  isSelect?: boolean
  onClick?: () => void
}[]

export interface ProjectFilter {
  projectUrlName?: string
  sort?: string
  order?: string
}

enum FiltersEnum {
  SORT = 'sort',
  ORDER = 'order',
}

const getFilterOptions = (data: FilterType, select: string, action: (filterName: string) => void) =>
  data.map((option) => ({
    ...option,
    isSelect: select === option.by,
    onClick: () => action(option.by),
  }))

export const FlowsPage = () => {
  const { t } = useTranslation()
  const { projectUrlName } = useParams() as { projectUrlName: string }

  const isHiddenFlowEnabled = useFeatureFlag(FEATURE_FLAGS.HIDDEN_FLOW) ?? false
  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isProjectSuccess, data: project } = useProjectQuery({ projectUrlName })
  const { isSuccess: isFlowsSuccess, data: flowsData } = useFlowsQuery({ projectUrlName })
  const { isSuccess: isUnassignedTracesSuccess, data: unassignedTracesData } =
    useUnassignedTracesQuery({
      projectUrlName,
    })
  useIoGuidesRedirect({ user, project })

  const isLoaded = isFlowsSuccess && isUnassignedTracesSuccess && isUserSuccess && isProjectSuccess

  const teamRole = user?.roles.teams[project?.team.id as number]
  const projectRole = user?.roles.projects[project?.id as number]

  const isSuperAdmin = user?.roles.isSuperAdmin
  const isTeamAdmin = teamRole === TeamRoleApi.ADMIN
  const isProjectAdmin = projectRole === ProjectRoleApi.ADMIN
  const isSuperAdminOrTeamAdmin = isSuperAdmin || isTeamAdmin
  const isSuperOrTeamOrProjectAdmin = isSuperAdminOrTeamAdmin || isProjectAdmin

  const canWorkWithFlows =
    !isSuperAdmin || (teamRole && teamRole !== TeamRoleApi.NONE) || projectRole

  const getCurrentProjectFilters = useCallback(() => {
    return psLocalStorage
      .getFlowsFilters()
      .find((filter: ProjectFilter) => filter.projectUrlName === projectUrlName)
  }, [projectUrlName])

  const [filterSortBy, setFilterSortBy] = useState(getCurrentProjectFilters()?.sort || 'popularity')
  const [filterOrderBy, setFilterOrderBy] = useState(getCurrentProjectFilters()?.order || 'asc')

  const writeFiltersDataToStorage = (filterType: FiltersEnum, filterName: string) => {
    if (getCurrentProjectFilters()) {
      const updatedFilters = psLocalStorage.getFlowsFilters()
      const currentProjectFilterIndex = updatedFilters.findIndex(
        (projectFilters: ProjectFilter) => projectFilters.projectUrlName === projectUrlName,
      )
      updatedFilters[currentProjectFilterIndex][filterType] = filterName
      psLocalStorage.setFlowsFilters(updatedFilters)
    } else {
      const newProject: ProjectFilter = { projectUrlName: projectUrlName }
      newProject[filterType] = filterName
      const newFilters = [...psLocalStorage.getFlowsFilters(), newProject]
      psLocalStorage.setFlowsFilters(newFilters)
    }
  }

  const handleFilterSortByChange = (filterName: string) => {
    writeFiltersDataToStorage(FiltersEnum.SORT, filterName)
    setFilterSortBy(filterName)
    handleFilterOrderByChange('desc')
  }

  const handleFilterOrderByChange = (filterName: string) => {
    writeFiltersDataToStorage(FiltersEnum.ORDER, filterName)
    setFilterOrderBy(filterName)
  }

  const filters = [
    {
      name: t('flows.flowsPage.filters.sortBy.name'),
      options: getFilterOptions(
        [
          {
            name: t('flows.flowsPage.filters.sortBy.options.popular'),
            by: 'popularity',
          },
          {
            name: t('flows.flowsPage.filters.sortBy.options.favorites'),
            by: 'flowUserInfo.favorite',
          },
          {
            name: t('flows.flowsPage.filters.sortBy.options.alphabetical'),
            by: 'name',
          },
          {
            name: t('flows.flowsPage.filters.sortBy.options.createdDate'),
            by: 'dateCreated',
          },
          {
            name: t('flows.flowsPage.filters.sortBy.options.editedDate'),
            by: 'dateUpdated',
          },
          {
            name: t('flows.flowsPage.filters.sortBy.options.createdBy'),
            by: 'author.authorName',
          },
        ],
        filterSortBy,
        handleFilterSortByChange,
      ),
    },
    {
      name: t('flows.flowsPage.filters.order.name'),
      options: getFilterOptions(
        [
          {
            name: t('flows.flowsPage.filters.order.options.ascending'),
            by: 'asc',
          },
          {
            name: t('flows.flowsPage.filters.order.options.descending'),
            by: 'desc',
          },
        ],
        filterOrderBy,
        handleFilterOrderByChange,
      ),
    },
  ]

  const flowsItems =
    flowsData
      ?.map((item) => ({ ...item, name: getFlowName(item.name, item.projectLocalId) }))
      .filter((item) => !(!isHiddenFlowEnabled && item.hidden)) || []

  useEffect(() => {
    setFilterSortBy(getCurrentProjectFilters()?.sort || 'popularity')
    setFilterOrderBy(getCurrentProjectFilters()?.order || 'asc')
  }, [projectUrlName, getCurrentProjectFilters])

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const isReadOnlyProject = useIsReadOnlyProject()

  const [flowFormModalOpened, setFlowFormModalOpened] = useState(false)
  const handleCreateNewFlowClick = () => setFlowFormModalOpened(true)
  const handleFlowFormModalClose = () => setFlowFormModalOpened(false)

  const pageTitle = project?.name

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<ProjectFlowsPageBreadcrumbs />}
        centerContent={<PageTitle titleKey="library" />}
      />
    ),
    [],
  )

  return (
    <Layout headerComponent={header} pageConfig={{ withBottomButton: true }}>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      {isLoaded && (
        <div className="flex flex-col flex-1 px-[24px]">
          <div className="grid grid-cols-flows-head z-[2] items-center mt-[20px] mb-[23px]">
            <h1 className="text-header-big font-medium tracking-tight">
              {t('flows.flowsPage.title')}
            </h1>
            {unassignedTracesData && unassignedTracesData?.length > 0 && (
              <div className="px-[10px] text-small tracking-wide pt-[4px] text-center text-gray-normal">
                {t('flows.flowsPage.unassignedTraces', {
                  count: unassignedTracesData?.length,
                })}
                {canWorkWithFlows && (
                  <Button
                    as={Link}
                    to={generatePath(PATH_UNASSIGNED, { projectUrlName })}
                    variant={ButtonVariantEnum.Text}
                    textColorVariant={ButtonTextColorVariantEnum.Look}
                    className="inline-block pl-[6px] ml-[6px]"
                  >
                    {t('flows.flowsPage.reviewAndAssign')}
                    <ButtonIcon icon="arrow-small-r" />
                  </Button>
                )}
              </div>
            )}
            <div className="col-start-3 flex items-center justify-end pt-[4px] whitespace-nowrap">
              {isSuperAdminOrTeamAdmin && user && (
                <ProjectSettings user={user} project={project as ProjectDto} isFlowPage />
              )}
              {isSuperOrTeamOrProjectAdmin && (
                <Button
                  as={Link}
                  to={generatePath(PATH_PROJECT_CONTRIBUTORS, {
                    projectUrlName: project?.urlName as string,
                  })}
                  className="ml-[14px]"
                  variant={ButtonVariantEnum.Text}
                  textColorVariant={ButtonTextColorVariantEnum.Muted}
                  withIcon
                >
                  {t('projects.projectCard.projectContributors')} <ButtonIcon icon="user" />
                </Button>
              )}
              {flowsItems && flowsItems?.length > 0 && (
                <>
                  <Popover
                    popoverClassName="relative inline-block"
                    menuSections={filters}
                    buttonClass={(open) =>
                      classNames(
                        'ml-4 flex items-center pl-[8px] text-small tracking-wide font-medium transition',
                        open ? 'text-white' : 'text-gray-normal',
                      )
                    }
                    buttonChildren={() => (
                      <>
                        {filters[0].options.find((option) => option?.by === filterSortBy)?.name}
                        <ButtonIcon icon="arrow-drop-d" />
                      </>
                    )}
                    withSelect
                  />
                  {canWorkWithFlows && !isReadOnlyProject && (
                    <Button
                      className="ml-[14px]"
                      variant={ButtonVariantEnum.Outlined}
                      onClick={handleCreateNewFlowClick}
                    >
                      {t('flows.flowsPage.createNewFlow')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          {flowsItems && flowsItems?.length > 0 ? (
            <ul className="grid grid-cols-2 gap-[24px] pb-[24px] 1280:grid-cols-3 1680:grid-cols-4">
              {flowsItems
                .sort((a, b) => {
                  const multiplier = filterOrderBy === 'desc' ? -1 : 1

                  if (get(a, filterSortBy) === get(b, filterSortBy)) {
                    return 0
                  } else {
                    return (
                      String(get(a, filterSortBy)).localeCompare(
                        String(get(b, filterSortBy)),
                        undefined,
                        {
                          numeric: true,
                          sensitivity: 'base',
                        },
                      ) * multiplier
                    )
                  }
                })
                .map((item) => (
                  <li key={item.projectLocalId}>
                    <FlowCard {...item} />
                  </li>
                ))}
            </ul>
          ) : (
            <Empty
              image={BittenBiscuitsSVG}
              title={t('flows.flowsPage.empty.title')}
              text={t('flows.flowsPage.empty.text')}
              buttons={
                canWorkWithFlows
                  ? [{ name: t('flows.flowsPage.empty.button'), onClick: handleCreateNewFlowClick }]
                  : []
              }
            />
          )}
        </div>
      )}
      <FlowFormModal isOpen={flowFormModalOpened} onClose={handleFlowFormModalClose} />
    </Layout>
  )
}
