import React from 'react'
import { DashboardRunDto } from 'api/__generated__/api-types'
import { useTranslation } from 'react-i18next'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { Link } from 'react-router-dom'
import { GridHeader } from 'components/flows/ra/runs/details/utils/GridHeader'
import { DataCol } from 'components/flows/ra/runs/details/utils/DataCol'
import { Title } from 'components/flows/ra/runs/details/utils/Title'

interface InstrumentedResultsProps {
  raStore: RaMvpStore
  run: DashboardRunDto
}

export const InstrumentedResults = ({ raStore, run }: InstrumentedResultsProps) => {
  const { t } = useTranslation()
  return (
    <div>
      <Title text={t('ra.flow.instrumentedResults.title')} />
      <GridHeader />
      <div className="grid grid-cols-3">
        <DataCol isDescription>{t('ra.flow.instrumentedResults.recordedTraces')}</DataCol>
        <DataCol>
          <ul>
            {run.results.current.instrumented?.traceIds?.map((traceId) => {
              const path = raStore.generateTracePath(traceId)
              return (
                <li key={traceId}>
                  <Link className="text-electro" to={path}>
                    trace {traceId}
                  </Link>
                </li>
              )
            })}
          </ul>
        </DataCol>
      </div>
    </div>
  )
}
