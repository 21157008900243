import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { Slice } from 'components/ps-chart/models/Slice'

/**
 * Get all connections from stored map of all slices starting
 * from start slice through all it's ancestor parents until root slice
 **/
export const getStackChainsLinks = (
  startSlice: Slice,
  sliceLinksById: Map<number, ReadonlyArray<SliceLink>>,
  isGoUpDisabled = false,
): SliceLink[] => {
  const chainsRoots: SliceLink[] = []

  let curSlice = startSlice
  while (true) {
    const curLinks = sliceLinksById.get(curSlice.id)
    if (curLinks != null && curLinks.length > 0 && !curSlice.title.startsWith('UnityPlayer')) {
      chainsRoots.push(...curLinks)
    }
    if (curSlice.parent == null || isGoUpDisabled) {
      break
    }
    curSlice = curSlice.parent
  }

  return chainsRoots
}
