import { makeAutoObservable, observable } from 'mobx'

import { Api } from 'api/Api'
import { TracePageParams } from 'api/models'
import { Thread } from 'components/ps-chart/models/Thread'
import { parseSlicesToThreadsFromApi } from 'components/ps-chart/utils/parser'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { Slice } from 'components/ps-chart/models/Slice'
import { getSliceSize } from 'components/ps-chart/utils/slice'
import { TraceDataDto } from 'components/ps-chart/models/TraceDataDto'

export type ReadonlySliceById = ReadonlyMap<number, Slice>
export type SliceById = Map<number, Slice>

export interface TraceDataState {
  mainThread: Readonly<Thread> | null
  utilityThreads: Thread[]
  threads: ReadonlyArray<Thread>
  threadsById: ReadonlyMap<number, Thread>
  sliceById: ReadonlySliceById
  reactQueueThread: Thread | null
  hasReactQueueThread: boolean
  reactJSThreadIds: Set<number>
  minSliceLengthNs: number
  traceStart: number
  traceEnd: number
}

export class TraceDataStore implements TraceDataState {
  private readonly api: Api
  private readonly tracePageParams: TracePageParams
  private readonly settings: PsChartSettings

  private _mainThread: Thread | null = null
  private _utilityThreads: Thread[] = []
  private _threads: Thread[] = []
  private _threadsById = new Map<number, Thread>()
  private _sliceById: SliceById = new Map()
  private _minSliceLengthNs = 0
  private _minTraceTimeNs = 0
  private _maxTraceTimeNs = 0

  constructor(api: Api, tracePageParams: TracePageParams, settings: PsChartSettings) {
    makeAutoObservable<
      TraceDataStore,
      'api' | 'tracePageParams' | 'settings' | '_threads' | '_threadsById' | '_sliceById'
    >(this, {
      api: false,
      settings: false,
      tracePageParams: false,
      _threads: observable.ref,
      _threadsById: observable.shallow,
      _sliceById: false,
    })
    this.tracePageParams = tracePageParams
    this.api = api
    this.settings = settings
  }

  load(): Promise<void> {
    return this.api.getTrace(this.tracePageParams).then((traceData) => {
      this.process(traceData)
    })
  }

  process(traceData: TraceDataDto) {
    const { threads, utilityThreads } = parseSlicesToThreadsFromApi(
      traceData,
      this.settings.renderEngine.palette,
    )
    this.clear()
    this.setTraceMainThread(threads[0])
    this.setUtilityThreads(utilityThreads)
    this.setTraceThreads(threads)
    this.setMinMaxTrace()
  }

  get traceStart(): number {
    return this._minTraceTimeNs
  }

  get traceEnd(): number {
    return this._maxTraceTimeNs
  }

  get mainThread(): Readonly<Thread> | null {
    return this._mainThread
  }

  get threads(): ReadonlyArray<Thread> {
    return this._threads
  }

  get utilityThreads(): Thread[] {
    return this._utilityThreads
  }

  get threadsById(): ReadonlyMap<number, Thread> {
    return this._threadsById
  }

  get sliceById(): ReadonlySliceById {
    return this._sliceById
  }

  get minSliceLengthNs(): number {
    return this._minSliceLengthNs
  }

  setTraceMainThread(thread: Thread) {
    this._mainThread = thread
  }

  setUtilityThreads(threads: Thread[]) {
    this._utilityThreads = threads
    for (const thread of threads) {
      this._threadsById.set(thread.id, thread)
      for (let level = 0; level <= thread.maxLevel; level++) {
        const slices = thread.slicesByLevel.get(level) ?? []
        for (let index = 0; index < slices.length; index++) {
          const slice = { ...slices[index], levelPositionIndex: index }
          this._sliceById.set(slice.id, slice)
        }
      }
    }
  }

  setMinMaxTrace() {
    let xMin = Number.MAX_SAFE_INTEGER
    let xMax = Number.MIN_SAFE_INTEGER
    for (const [, thread] of this.threadsById.entries()) {
      // Children slice couldn't be outside parent range - we don't need to go through children for horizontal max & min
      for (const slice of thread.slices) {
        xMin = Math.min(slice.start, xMin)
        xMax = Math.max(slice.end, xMax)
      }
    }
    this._minTraceTimeNs = xMin
    this._maxTraceTimeNs = xMax
  }

  public setTraceThreads(threads: Thread[]) {
    this._threads = threads

    let minSlice: Slice | null = null
    let minSize: number = Number.MAX_SAFE_INTEGER

    for (const thread of threads) {
      this._threadsById.set(thread.id, thread)

      for (let level = 0; level <= thread.maxLevel; level++) {
        const slices = thread.slicesByLevel.get(level) ?? []
        for (let index = 0; index < slices.length; index++) {
          const slice = slices[index]
          this._sliceById.set(slice.id, slice)

          const currentSize = getSliceSize(slice)
          if (currentSize > 0 && currentSize < minSize) {
            minSize = currentSize
            minSlice = slice
          }
        }
      }
    }
    console.info('smallest slice', minSlice)
    this._minSliceLengthNs = minSize
  }

  get reactQueueThread(): Thread | null {
    return this.threads.find((thread) => thread.title.startsWith('mqt_js')) ?? null
  }

  get hasReactQueueThread(): boolean {
    return this.reactQueueThread !== null
  }

  get reactJSThreadIds(): Set<number> {
    return new Set<number>(
      this.threads
        .filter((thread) => thread.title !== undefined && thread.title.startsWith('js-thread'))
        .map((thread) => thread.id),
    )
  }

  private clear() {
    this._sliceById.clear()
    this._threadsById.clear()
    this._threads = []
    this._utilityThreads = []
    this._minSliceLengthNs = 0
  }
}
