import { Point } from 'components/ps-chart/models/helper-types'
import { getSliceTimelineTitle } from 'components/ps-chart/utils/slice'
import { moveToThread } from 'components/ps-chart/utils/moveTo'
import { Thread } from 'components/ps-chart/models/Thread'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { isSlice, Slice } from 'components/ps-chart/models/Slice'
import { PsChartFeatures, PsChartStore } from 'components/ps-chart/PsChartStore'

import { ConnectionsRender } from 'components/ps-chart/connections-render/ConnectionsRender'
import { getBorderRect } from 'components/ps-chart/utils/getBorderRect'
import { getSliceVisibleRect } from 'components/ps-chart/utils/getSliceVisibleRect'
import {
  ConnectionCurves,
  getEmptyConnectionCurves,
} from 'components/ps-chart/connections-render/ConnectionCurves'

import { throttle } from 'throttle-debounce'
import { TextMeasurer } from 'components/ps-chart/flame-chart/TextMeasurer'
import { Toaster } from 'hooks/useToaster'
import { Analytics } from 'utils/analytics'
import { PsChartEventsHandler } from 'components/ps-chart/PsChartEventsHandler'
import { RendererType } from 'components/ps-chart/stores/ChartRendererStore'
import { ACTION_CHANGE_CHART_RENDER_TYPE } from 'components/ps-chart/actions-panel/RenderTypeModeAction'
import { LayoutContextInterface } from 'contexts/layout-context'
import { Pointer } from './Pointer'
import { MeasurementPoints, RenderEngine, RenderEngineData } from './RenderEngine'

/**
 * Flame-chart drawing root class
 *
 * Responsibilities:
 * - handles user events (clicks, hover, zoom, etc)
 * - delegates state to {@link PsChartStore}
 * - delegates rendering to {@link RenderEngine}
 */
export class FlameChartRender {
  readonly mainCanvas: HTMLCanvasElement
  readonly mainContext: CanvasRenderingContext2D
  readonly mainRenderEngine: RenderEngine
  readonly baseTextMeasurer: TextMeasurer

  readonly baseFontStyle: string

  readonly pinnedCanvas: HTMLCanvasElement
  readonly pinnedContext: CanvasRenderingContext2D
  readonly pinnedRenderEngine: RenderEngine

  private readonly settings: PsChartSettings
  private readonly features: PsChartFeatures

  private startDragPoint: Point | null = null

  private lastAnimationSlice: number | null = null

  private readonly psChartStore: PsChartStore

  private readonly pointer: Pointer

  private readonly connectionsRender: ConnectionsRender

  private pressedKey: null | string = null

  private readonly toaster: Toaster
  private analytics: Analytics
  private layout: LayoutContextInterface
  private readonly psChartEventsHandler: PsChartEventsHandler

  constructor(
    canvas: HTMLCanvasElement,
    pinnedCanvas: HTMLCanvasElement,
    psChartStore: PsChartStore,
    layout: LayoutContextInterface,
    toaster: Toaster,
    analytics: Analytics,
  ) {
    this.psChartStore = psChartStore
    this.toaster = toaster
    this.analytics = analytics
    this.layout = layout
    this.settings = psChartStore.chartSettings
    this.features = psChartStore.chartFeatures
    this.baseFontStyle = `${this.settings.renderEngine.basicRenderer.fontSize}px ${this.settings.renderEngine.basicRenderer.fontFamily}`
    this.baseTextMeasurer = new TextMeasurer(this.baseFontStyle)
    this.connectionsRender = new ConnectionsRender(psChartStore)

    const mainContext = canvas.getContext('2d', { alpha: false })
    if (mainContext == null) {
      throw new Error('canvas.getContext failed!')
    }
    this.mainCanvas = canvas
    this.mainContext = mainContext
    this.mainRenderEngine = new RenderEngine(
      mainContext,
      this.settings.renderEngine,
      this.baseFontStyle,
      this.baseTextMeasurer,
    )

    const favContext = pinnedCanvas.getContext('2d', { alpha: false })
    if (favContext == null) {
      throw new Error('favoritesCanvas.getContext failed!')
    }

    this.pinnedCanvas = pinnedCanvas
    this.pinnedContext = favContext
    this.pinnedRenderEngine = new RenderEngine(
      favContext,
      this.settings.renderEngine,
      this.baseFontStyle,
      this.baseTextMeasurer,
    )

    this.pointer = new Pointer(this.mainCanvas, this.pinnedCanvas)
    this.psChartEventsHandler = new PsChartEventsHandler(psChartStore, this.pointer)

    this.setCanvasSizeParams()
  }

  addEventListeners(wrapperEl: HTMLElement) {
    wrapperEl.addEventListener('wheel', this.onWheel)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)

    wrapperEl.addEventListener('mousedown', this.onMouseDown)
    wrapperEl.addEventListener('mousemove', this.onMouseMove)
    wrapperEl.addEventListener('mouseup', this.onMouseUp)
    wrapperEl.addEventListener('mouseleave', this.onMouseOut)

    this.psChartEventsHandler.addEventListeners(wrapperEl)
  }

  removeEventListeners(wrapperEl: HTMLElement) {
    wrapperEl.removeEventListener('wheel', this.onWheel)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)

    wrapperEl.removeEventListener('mousedown', this.onMouseDown)
    wrapperEl.removeEventListener('mousemove', this.onMouseMove)
    wrapperEl.removeEventListener('mouseup', this.onMouseUp)
    wrapperEl.removeEventListener('mouseleave', this.onMouseOut)

    this.psChartEventsHandler.removeEventListeners(wrapperEl)
  }

  private readonly onKeyDown = (event: KeyboardEvent) => {
    if (['KeyD', 'KeyA', 'KeyW', 'KeyS'].includes(event.code)) {
      this.hideTooltip()
    }

    if (event.code === 'Escape') {
      if (this.psChartStore.isLinkModeActive) {
        this.psChartStore.disableLinkMode()
      }
    }

    if (event.code === 'KeyP') {
      this.psChartStore.traceAnalyzeStore.toggleThreadForceMaxLevelMode()
    }

    if (event.code === 'KeyT') {
      this.psChartStore.enableTransparentConnection()
    }

    if (event.code === 'KeyO') {
      this.psChartStore.toggleIsDimDisconnectedSlicesEnabled()
    }

    if (this.psChartStore.chartFeatures.measurementTool) {
      if (event.code === 'ShiftLeft') {
        const point = this.pointer.getRelativePoint()
        if (point && !this.pointer.isOut()) {
          this.psChartStore.setMeasurementPoints({
            startTime: this.psChartStore.getTimeByX(point.x),
            y: (this.pointer.isMain() ? this.psChartStore.vState.yStart : 0) + point.y,
            isMain: this.pointer.isMain(),
          })
          this.psChartStore.enableMeasurementMode()
        }
      }
    }

    this.pressedKey = event.code
  }

  private readonly onKeyUp = (event: KeyboardEvent) => {
    this.pressedKey = null

    if (event.code === 'ShiftLeft') {
      this.hideMeasurement()
    }

    if (event.code === 'KeyT') {
      this.psChartStore.disableTransparentConnection()
    }
  }

  private readonly onWheel = (event: WheelEvent) => {
    event.preventDefault()
    this.psChartStore.setHoveredSliceId(null)
    this.hideTooltip()
    if (event.deltaY !== 0 && event.ctrlKey) {
      if (event.deltaY < 0) {
        this.psChartStore.hState.increaseZoom(this.pointer.getRelativeX(), event.deltaY)
      } else {
        this.psChartStore.hState.decreaseZoom(this.pointer.getRelativeX(), event.deltaY)
      }
    }
    return false
  }

  private readonly onMouseDown = (event: MouseEvent) => {
    if (!event.ctrlKey) {
      this.startDragPoint = { x: event.x, y: event.y }
    }
  }

  private readonly onMouseMove = (event: MouseEvent) => {
    this.pointer.onMouseMove(event)
    this.handleThreadHover()
    this.onHover()
    if (!event.ctrlKey && this.startDragPoint != null) {
      const mouseMoveDelta = this.startDragPoint.x - event.x
      const delta = this.calcChangeXStartDelta(
        mouseMoveDelta * 2 * this.psChartStore.hState.timePerPx,
      )
      if (this.pointer.isMain()) {
        this.psChartStore.vState.scrollY(
          this.calcChangeXStartDelta(this.startDragPoint.y - event.y),
        )
      }
      this.psChartStore.hState.changeXStart(delta)

      this.startDragPoint = { x: event.x, y: event.y }
    }
    if (this.psChartStore.isLinkModeActive) {
      this.prepareDataAndRender()
    }

    if (this.psChartStore.isMeasurementModeActive) {
      this.updateMeasurementPoints()
    }
  }

  private readonly handleThreadHover = () => {
    const { thread } = this.psChartStore.getElementsInChartByPointer(this.pointer)
    this.psChartStore.setHoveredThreadId(thread?.id || null)
  }

  private readonly onMouseUp = (event: MouseEvent) => {
    if (
      !event.ctrlKey &&
      this.startDragPoint != null &&
      this.pointer.isEqualTo({
        x: this.startDragPoint.x,
        y: this.startDragPoint.y,
      })
    ) {
      if (!this.psChartStore.shouldKeepActiveSelection) {
        this.onClick()
      }
      this.psChartStore.unlockSelection()
    }
    this.startDragPoint = null

    if (this.psChartStore.isMeasurementModeActive) {
      this.prepareDataAndRender()
    }
  }

  private readonly onMouseOut = () => {
    this.startDragPoint = null
    this.psChartStore.setHoveredThreadId(null)
    this.hideTooltip()
    this.pointer.onMouseOut()
    this.hideMeasurement()
    this.psChartStore.setHoveredSliceId(null)
  }

  onResize() {
    this.setCanvasSizeParams()
    if (this.psChartStore.isLoaded) {
      this.prepareDataAndRender()
    }
  }

  private calcChangeXStartDelta(delta: number): number {
    return (
      Math.sign(delta) *
      Math.max(1, Math.abs(Math.round(delta * this.settings.changePosCoefficient)))
    )
  }

  private updateMeasurementPoints = () => {
    const end = this.pointer.getRelativePoint()

    if (this.psChartStore.measurementPoints && end) {
      this.psChartStore.setMeasurementPoints({
        ...this.psChartStore.measurementPoints,
        endTime: this.psChartStore.getTimeByX(end.x),
      })
    }
  }

  prepareDataAndRender() {
    if (this.psChartStore.isDisposed) {
      return
    }
    if (!this.lastAnimationSlice) {
      this.lastAnimationSlice = requestAnimationFrame(() => {
        const all = performance.now()
        const processedMain = this.psChartStore.rendererStore.processedMainThreads
        const processedPinned = this.psChartStore.rendererStore.processedPinnedThreads
        const elementInChart = this.findElementInChart()
        const hoveredSlice = isSlice(elementInChart) ? elementInChart : null

        const canvasCurves = this.connectionsRender.getCanvasCurves(
          this.psChartStore.vState.mainThreadsTopMap,
          this.psChartStore.vState.pinnedThreadsTopMap,
          () => ({
            hoveredSlice,
            cursorPoint: this.pointer.getRelativePoint(),
            isCursorOnPinned: this.pointer.isPinned(),
          }),
        )

        this.mainRenderEngine.render(
          this.getRenderData(
            this.psChartStore,
            processedMain,
            false,
            this.psChartStore.isMeasurementModeActive,
            this.psChartStore.measurementPoints,
            canvasCurves.main,
          ),
        )
        this.pinnedRenderEngine.render(
          this.getRenderData(
            this.psChartStore,
            processedPinned,
            true,
            this.psChartStore.isMeasurementModeActive,
            this.psChartStore.measurementPoints,
            canvasCurves.pinned,
          ),
        )

        this.lastAnimationSlice = null
        const allRenderingTookTime = performance.now() - all
        console.debug('#RE all took', allRenderingTookTime.toFixed(2))

        this.psChartStore.renderingMeasuring.addMeasure(allRenderingTookTime)
        if (
          this.psChartStore.rendererStore.renderType === RendererType.MERGED &&
          this.psChartStore.renderingMeasuring.renderTimeIsSlow
        ) {
          this.layout.activateHint(ACTION_CHANGE_CHART_RENDER_TYPE)
        }
      })
    }
  }

  getRenderData(
    psChartStore: PsChartStore,
    threads: Thread[],
    isPinned: boolean,
    shouldRenderMeasurement: boolean,
    measurementPoints: MeasurementPoints,
    connectionCurves?: ConnectionCurves,
  ): RenderEngineData {
    return {
      psChartStore: psChartStore,
      threads: threads,
      isPinned: isPinned,
      shouldRenderMeasurement: shouldRenderMeasurement,
      activeLevelsByThreadId: psChartStore.traceAnalyzeStore.activeLevelsFromChainByThreadId,
      maxLevelByThreadId: psChartStore.traceAnalyzeStore.maxLevelByThreadId,
      hState: psChartStore.hState,
      vState: psChartStore.vState,
      flagsState: psChartStore.flagsStore,
      videoState: psChartStore.videoPlayerStore,
      annotationsState: psChartStore.annotationsStore,
      connectionCurves,
      isTransparentConnectionEnabled: psChartStore.isTransparentConnectionEnabled,
      isThreadShrunkModeEnabled:
        psChartStore.traceAnalyzeStore.chainExists &&
        psChartStore.traceAnalyzeStore.showOnlyActiveLevelsMode,
      measurementPoints,
      linkModeSlice: psChartStore.linkModeSliceId
        ? this.psChartStore.sliceById.get(psChartStore.linkModeSliceId)
        : undefined,
      delays: this.psChartStore.traceAnalyzeStore.chainDelays,
    }
  }

  private onClick() {
    this.psChartStore.flagsStore.clearSelected()
    const result = this.findElementInChart()
    if (this.psChartStore.isLinkModeActive) {
      if (result != null && this.psChartStore.linkModeSliceId != null && isSlice(result)) {
        const fromSlice = this.psChartStore.sliceById.get(this.psChartStore.linkModeSliceId)!
        this.psChartStore
          .connectSlices(fromSlice, result)
          .then(() => this.analytics.track('connect-slices'))
          .catch((reason) =>
            this.toaster.error(reason, 'psChart.error.connection.cantConnectSlices'),
          )
      }
    } else if (isSlice(result)) {
      const thread = this.psChartStore.traceDataState.threadsById.get(result.threadId)!
      const threadsTopBottomMap = this.psChartStore.traceAnalyzeStore.pinnedIdsSet.has(
        result.threadId,
      )
        ? this.psChartStore.vState.pinnedThreadsTopBottomMap
        : this.psChartStore.vState.mainThreadsTopBottomMap
      const [threadTop] = threadsTopBottomMap.get(result.threadId)!
      const relativePointY = this.pointer.getRelativePoint()!.y
      const offset = relativePointY - (this.psChartStore.vState.yStart + relativePointY - threadTop)

      this.psChartStore.setSelectedSlice(result)
      moveToThread(thread, this.psChartStore, offset)
    } else if (result != null && this.psChartStore.traceAnalyzeStore.selectedSlice != null) {
      const threadsTopBottomMap = this.psChartStore.traceAnalyzeStore.pinnedIdsSet.has(result.id)
        ? this.psChartStore.vState.pinnedThreadsTopBottomMap
        : this.psChartStore.vState.mainThreadsTopBottomMap
      this.psChartStore.setSelectedSlice(null)
      const [threadTop] = threadsTopBottomMap.get(result.id)!
      const relativePoint = this.pointer.getRelativePoint()
      if (relativePoint !== null) {
        const offset =
          relativePoint.y - (this.psChartStore.vState.yStart + relativePoint.y - threadTop)
        moveToThread(result, this.psChartStore, offset)
      }
    } else {
      return null
    }
  }

  private onHover = throttle(16, () => {
    const result = this.findElementInChart()
    if (isSlice(result)) {
      const title = this.isTextShrink(result) ? getSliceTimelineTitle(result) : null
      const point = this.pointer.absolutePointer

      if (title && point) {
        this.psChartStore.setTooltip({
          point,
          title,
        })
      }

      this.psChartStore.setHoveredSliceId(result.id)
    } else {
      this.psChartStore.setHoveredSliceId(null)
      this.hideTooltip()
    }
  })

  private hideTooltip() {
    this.psChartStore.setTooltip(null)
  }

  getSliceRectAndThread = (slice: Slice) => {
    const thread = this.psChartStore.traceDataState.threadsById.get(slice.threadId)!
    const threadActiveLevels =
      this.psChartStore.traceAnalyzeStore.activeLevelsFromChainByThreadId.get(slice.threadId) ?? []

    return {
      rect: getBorderRect(
        getSliceVisibleRect(
          thread,
          threadActiveLevels,
          slice,
          this.psChartStore,
          this.settings.sliceRectMinWidth,
        ),
        this.settings.renderEngine.foundSliceBorderWidth,
      ),
      thread,
    }
  }

  isTextShrink = (slice: Slice) => {
    const {
      rect: { w },
    } = this.getSliceRectAndThread(slice)
    const title = getSliceTimelineTitle(slice)
    const maxWidth = w - this.settings.renderEngine.basicRenderer.blockPaddingX * 2
    const textToDraw = this.baseTextMeasurer.getEllipsizedText(title, maxWidth)
    return textToDraw !== title
  }

  findElementInChart(): Slice | Thread | null {
    const elements = this.psChartStore.getElementsInChartByPointer(this.pointer)
    return elements.slice || elements.thread || null
  }

  setCanvasSizeParams() {
    const { pinnedCanvasHeight } = this.psChartStore.vState

    // TODO: can be fractional! What should we do in this case?
    const pixelRatio = window.devicePixelRatio
    const fullChartWidth = this.psChartStore.hState.width

    this.pinnedCanvas.style.width = `${fullChartWidth}px`
    this.pinnedCanvas.style.height = `${pinnedCanvasHeight}px`
    this.pinnedCanvas.width = Math.floor(fullChartWidth * pixelRatio)
    this.pinnedCanvas.height = Math.floor(pinnedCanvasHeight * pixelRatio)

    const { mainCanvasHeight } = this.psChartStore.vState
    this.mainCanvas.style.width = `${fullChartWidth}px`
    this.mainCanvas.style.height = `${mainCanvasHeight}px`
    this.mainCanvas.style.top = `${pinnedCanvasHeight}px`
    this.mainCanvas.width = Math.floor(fullChartWidth * pixelRatio)
    this.mainCanvas.height = Math.floor(mainCanvasHeight * pixelRatio)

    this.pinnedContext.scale(pixelRatio, pixelRatio)
    this.mainContext.scale(pixelRatio, pixelRatio)

    this.mainRenderEngine.renderEmpty(
      this.getRenderData(
        this.psChartStore,
        [],
        false,
        false,
        this.psChartStore.measurementPoints,
        getEmptyConnectionCurves(),
      ),
    )
    this.pinnedRenderEngine.renderEmpty(
      this.getRenderData(
        this.psChartStore,
        [],
        true,
        false,
        this.psChartStore.measurementPoints,
        getEmptyConnectionCurves(),
      ),
    )
  }

  private hideMeasurement() {
    if (!this.psChartStore.isMeasurementModeActive) {
      return
    }
    this.psChartStore.disableMeasurementMode()
  }
}
