import { DashboardRunDto } from 'api/__generated__/api-types'
import {
  getRegressionStatus,
  isFinished,
  RegressionStatus,
} from 'components/flows/ra/runs/runUtils'
import { Icon } from 'components/Icon'
import React from 'react'

interface RegressionIconProps {
  run: DashboardRunDto
}

export const RegressionIcon = ({ run }: RegressionIconProps) => {
  if (!isFinished(run)) {
    return <Icon icon="more" className="text-icon" />
  } else {
    const status = getRegressionStatus(run)
    switch (status) {
      case RegressionStatus.NOT_DETECTED:
        return <span className="w-8"></span>
      case RegressionStatus.SLOWDOWN:
        return <Icon icon="arrow-small-d" className="text-icon text-state-bad" />
      case RegressionStatus.SPEEDUP:
        return <Icon icon="arrow-small-u" className="text-icon text-state-good" />
    }
  }
}
