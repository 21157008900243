export const AnnotationDelay = {
  NO_DELAY: 'NO_DELAY',
  MODERATE_DELAY: 'MODERATE_DELAY',
  EXTREME_DELAY: 'EXTREME_DELAY',
} as const

export const AppStartType = {
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  COLD_START: 'COLD_START',
  RETURN: 'RETURN',
} as const

export const BackendConfigValidationBackendConfigNameToValidator = {
  TRACE_QUALITY_CONFIG_UNDERFILTER: 'TRACE_QUALITY_CONFIG_UNDERFILTER',
  TRACE_QUALITY_CONFIG_OVERFILTER: 'TRACE_QUALITY_CONFIG_OVERFILTER',
  ANDROID_PLUGIN_VERSIONS: 'ANDROID_PLUGIN_VERSIONS',
  UNKNOWN: 'UNKNOWN',
} as const

export const BuildDtoUploadState = {
  UPLOADING: 'UPLOADING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
} as const

export const BuildType = {
  INSTRUMENTED_APK: 'INSTRUMENTED_APK',
  APK: 'APK',
} as const

export const ComputationStatus = {
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
} as const

export const ComputationType = {
  TRACE_QUALITY: 'TRACE_QUALITY',
  TRACE_FILTER: 'TRACE_FILTER',
  SLICE_MATCHING: 'SLICE_MATCHING',
} as const

export const DashboardRunDtoStatus = {
  UNDEFINED: 'UNDEFINED',
  AWAITING_UPLOAD: 'AWAITING_UPLOAD',
  READY_TO_START: 'READY_TO_START',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  ANALYZING: 'ANALYZING',
  CANCELLED: 'CANCELLED',
  CANCEL_REQUESTED: 'CANCEL_REQUESTED',
} as const

export const FeatureId = {
  EDIT_AIRTABLE_CLIENT_ID: 'EDIT_AIRTABLE_CLIENT_ID',
  ANNOTATIONS_SLICE_SUGGESTION: 'ANNOTATIONS_SLICE_SUGGESTION',
  GET_TRACE_URLS_FOR_FLOW: 'GET_TRACE_URLS_FOR_FLOW',
} as const

export const FlagSource = {
  USER: 'USER',
  INSTRUMENTATION: 'INSTRUMENTATION',
} as const

export const FlowAutomationConfigDtoInstrumentedRunConditionDto = {
  NEVER: 'NEVER',
  ALWAYS: 'ALWAYS',
  ON_ANY_CHANGE: 'ON_ANY_CHANGE',
} as const

export const FlowAutomationConfigDtoPriorityDto = {
  LOW: 'LOW',
  REGULAR: 'REGULAR',
  HIGH: 'HIGH',
} as const

export const FreeTrialStage = {
  PLATFORM_CHOICE: 'PLATFORM_CHOICE',
  INSTRUCTIONS: 'INSTRUCTIONS',
  FLOW_LIBRARY: 'FLOW_LIBRARY',
} as const

export const InstrumentationConfigSource = {
  PROJECT: 'PROJECT',
  DEFAULT: 'DEFAULT',
} as const

export const InstrumentedAppStatusDtoStatus = {
  SUCCESS: 'SUCCESS',
} as const

export const LiveDemoContentDtoContentType = {
  CONTENTFUL: 'CONTENTFUL',
} as const

export const LiveDemoTargetDtoTargetType = {
  GLOBAL: 'GLOBAL',
  FLOW: 'FLOW',
} as const

export const NamedLinkType = {
  SYNC: 'SYNC',
  ASYNC: 'ASYNC',
  OBJECT: 'OBJECT',
  RUNNABLE: 'RUNNABLE',
  DIRECT: 'DIRECT',
} as const

export const NotificationTopic = {
  RUN_FINISHED: 'RUN_FINISHED',
  RUN_FAILED: 'RUN_FAILED',
  REGRESSION_DETECTED: 'REGRESSION_DETECTED',
  PROGRESSION_DETECTED: 'PROGRESSION_DETECTED',
  CHANGE_DETECTED: 'CHANGE_DETECTED',
} as const

export const NotificationType = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
} as const

export const OsType = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
} as const

export const PasswordPolicy = {
  DEFAULT: 'DEFAULT',
  SECURE_CUSTOMER_1: 'SECURE_CUSTOMER_1',
} as const

export const ProjectBuildStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
} as const

export const ProjectBuildStatusOut = {
  NONE: 'NONE',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
} as const

export const ProjectMailingListOperation = {
  ADD: 'ADD',
  DELETE: 'DELETE',
} as const

export const ProjectRoleApi = {
  ADMIN: 'ADMIN',
  CONTRIBUTOR: 'CONTRIBUTOR',
} as const

export const RaUserNotificationConfigDtoTopicDto = {
  RUN_FINISHED: 'RUN_FINISHED',
  RUN_FAILED: 'RUN_FAILED',
  REGRESSION_DETECTED: 'REGRESSION_DETECTED',
  PROGRESSION_DETECTED: 'PROGRESSION_DETECTED',
  CHANGE_DETECTED: 'CHANGE_DETECTED',
} as const

export const RegressionAnalysisRunDtoStatus = {
  UNDEFINED: 'UNDEFINED',
  AWAITING_UPLOAD: 'AWAITING_UPLOAD',
  READY_TO_START: 'READY_TO_START',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  ANALYZING: 'ANALYZING',
  CANCELLED: 'CANCELLED',
  CANCEL_REQUESTED: 'CANCEL_REQUESTED',
} as const

export const ReportResult = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
} as const

export const ScriptKindDto = {
  AUTOMATION: 'AUTOMATION',
  SETUP: 'SETUP',
} as const

export const SsoSigninDtoSignUpType = {
  INVITE_ONLY: 'INVITE_ONLY',
  FREE_TRIAL: 'FREE_TRIAL',
  LITE: 'LITE',
  NOT_ALLOWED: 'NOT_ALLOWED',
} as const

export const SupportIssueCategory = {
  BUILD_APP: 'BUILD_APP',
  RUN_APP: 'RUN_APP',
  APP_CRASH_OR_SLOW: 'APP_CRASH_OR_SLOW',
  DOWNLOAD_COMPANION_APP: 'DOWNLOAD_COMPANION_APP',
  INSTALL_COMPANION_APP: 'INSTALL_COMPANION_APP',
  ENABLE_TRACING: 'ENABLE_TRACING',
  RECORD_TRACE: 'RECORD_TRACE',
  RECORD_VIDEO: 'RECORD_VIDEO',
  MISSING_FRAME_COUNTER: 'MISSING_FRAME_COUNTER',
  UPLOAD_TRACE: 'UPLOAD_TRACE',
  UPLOAD_VIDEO: 'UPLOAD_VIDEO',
  TRACE_QUALITY: 'TRACE_QUALITY',
  OTHERS: 'OTHERS',
} as const

export const TeamRoleApi = {
  NONE: 'NONE',
  ADMIN: 'ADMIN',
  CONTRIBUTOR: 'CONTRIBUTOR',
} as const

export const TechType = {
  JVM: 'JVM',
  SWIFT: 'SWIFT',
  REACT_NATIVE: 'REACT_NATIVE',
} as const

export const TierFeatureResultType = {
  CALCULATED: 'CALCULATED',
  TEAM_OPEN_TIER: 'TEAM_OPEN_TIER',
  FEATURE_NOT_FOUND: 'FEATURE_NOT_FOUND',
  LOCAL_VALUE_NOT_PROVIDED: 'LOCAL_VALUE_NOT_PROVIDED',
  FEATURE_NOT_SET: 'FEATURE_NOT_SET',
  NEW_LIMIT: 'NEW_LIMIT',
} as const

export const TierFeatureType = {
  FLAG: 'FLAG',
  GLOBAL_THRESHOLD: 'GLOBAL_THRESHOLD',
  LOCAL_THRESHOLD: 'LOCAL_THRESHOLD',
  UNKNOWN: 'UNKNOWN',
} as const

export const TokenDest = {
  COOKIE: 'COOKIE',
  BODY: 'BODY',
} as const

export const TraceDtoTraceLabel = {
  IS_NOT_INSTRUMENTED: 'IS_NOT_INSTRUMENTED',
} as const

export const TraceDtoType = {
  TRACE: 'TRACE',
  LIVE_DEMO: 'LIVE_DEMO',
} as const

export const TraceLinkType = {
  CLOSURE: 'CLOSURE',
  MANUAL: 'MANUAL',
} as const

export const TraceProcessingErrorCode = {
  APP_ID_NOT_FOUND: 'APP_ID_NOT_FOUND',
  UNSUPPORTED_FILE_FORMAT: 'UNSUPPORTED_FILE_FORMAT',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  JS_TRACE_NOT_FOUND: 'JS_TRACE_NOT_FOUND',
  JS_TRACE_MALFORMED: 'JS_TRACE_MALFORMED',
  JS_TRACE_LOCATION_MAP_NOT_FOUND: 'JS_TRACE_LOCATION_MAP_NOT_FOUND',
  ANDROID_TRACE_RN_RECORDING_ID_MISSING: 'ANDROID_TRACE_RN_RECORDING_ID_MISSING',
  ANDROID_TRACE_CLOCK_MISSING: 'ANDROID_TRACE_CLOCK_MISSING',
  TIMEOUT: 'TIMEOUT',
  TRACE_IS_NOT_INSTRUMENTED: 'TRACE_IS_NOT_INSTRUMENTED',
} as const

export const TraceProcessingState = {
  UPLOADING: 'UPLOADING',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
} as const

export const TraceQuality = {
  UNDERFILTERED: 'UNDERFILTERED',
  OVERFILTERED: 'OVERFILTERED',
  UNDERFILTERED_WARNING: 'UNDERFILTERED_WARNING',
  OVERFILTERED_WARNING: 'OVERFILTERED_WARNING',
} as const

export const TraceQualityCheckerCheckerFunction = {
  LESS_THAN: 'LESS_THAN',
  MORE_THAN: 'MORE_THAN',
} as const

export const UnifiedEntityType = {
  TRACE: 'TRACE',
  FLOW: 'FLOW',
} as const

export const VideoProcessingStateDto = {
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
} as const
