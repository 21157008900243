import { DashboardRunDto } from 'api/__generated__/api-types'
import React from 'react'
import { GridHeader } from 'components/flows/ra/runs/details/utils/GridHeader'
import { DataCol } from 'components/flows/ra/runs/details/utils/DataCol'
import { useTranslation } from 'react-i18next'
import { Title } from 'components/flows/ra/runs/details/utils/Title'

interface DataProps {
  run: DashboardRunDto
}

export const Data = ({ run }: DataProps) => {
  const { t } = useTranslation()
  const current = run.results.current.nonInstrumented
  const prev = run.results.previous?.nonInstrumented
  return (
    <div>
      <Title text={t('ra.flow.run.title')} />
      <div>
        <GridHeader />
        <div className="grid grid-cols-3">
          <DataCol isDescription>{t('ra.flow.run.durations')}</DataCol>
          <DataCol>{current?.durationsMs?.join(', ')}</DataCol>
          <DataCol>{prev?.durationsMs?.join(', ')}</DataCol>
        </div>
      </div>
    </div>
  )
}
