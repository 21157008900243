import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'

export const mergeSameTargetSliceChains =
  (sliceById: ReadonlySliceById) => (chainsRoots: SliceLink[]) => {
    const chainsRootsByTarget = new Map<number, SliceLink>()
    for (const chainRoot of chainsRoots) {
      const { toSliceId } = chainRoot
      const savedRoot = chainsRootsByTarget.get(toSliceId)
      if (savedRoot == null) {
        chainsRootsByTarget.set(toSliceId, chainRoot)
      } else {
        const curFromSlice = sliceById.get(chainRoot.fromSliceId)!
        const savedFromSlice = sliceById.get(savedRoot.fromSliceId)!
        if (curFromSlice.level < savedFromSlice.level) {
          chainsRootsByTarget.set(toSliceId, chainRoot)
        }
      }
    }
    return chainsRoots.filter((link) => chainsRootsByTarget.get(link.toSliceId) === link)
  }
