import {
  SliceDto,
  ThreadDto,
  TraceDataDto,
  UtilityThreadDto,
} from 'components/ps-chart/models/TraceDataDto'
import { ChartPalette, UtilityPalette, SlicePalette } from 'components/ps-chart/models/settings'
import { getAsyncSliceColor } from 'components/ps-chart/utils/getSliceDimmedColor'
import { Thread } from '../models/Thread'
import { OBJECT_ID_EMPTY_VALUE, Slice } from '../models/Slice'

interface ParseResult {
  maxLevel: number
  slices: Slice[]
}

const FRAME_120FPS_AVERAGE_LENGTH = 12 * 1e6
const FRAME_60FPS_AVERAGE_LENGTH = 18 * 1e6

const LEGACY_FRAME_SLICE_TITLE = /(^Frames PSi:Frame)/

/**
 * iOS JSON Traces has NR slices titles starting with "NetworkRequest ..."
 * Android Systrace Traces is more complicated and may have slices which ends with "...Interceptor intercept"
 **/
const SLICE_NETWORK_REQUEST_TITLE_PATTERNS = /(^NetworkRequest|Interceptor intercept$)/

/**
 * iOS JSON Traces has event slices titles starting with "RT:UIWindow sendEvent: ..."
 * Android Systrace Traces slices titles starting with  "deliverInputEvent ..."
 **/
const SLICE_LOGGED_EVENT_TITLE_PATTERNS = /(^RT:UIWindow sendEvent:|^deliverInputEvent)/

type SliceFreqByTitle = Map<string, number>
type SliceColorsByTitle = Map<string, string>

const isValidThread = (thread?: ThreadDto | UtilityThreadDto): thread is ThreadDto => {
  return !!thread?.slices?.length
}

const parseFramesThread = (thread: ThreadDto, palette: UtilityPalette): Thread => {
  const { id, slices } = thread

  const averageStats = new Map<number, number>()
  let mostRecurringCounter = 0
  let mostRecurringDuration = 0
  slices.map(({ start, end }) => {
    const duration = Math.round((end - start) / 1e6)
    const durationCounter = averageStats.has(duration) ? averageStats.get(duration)! + 1 : 1
    averageStats.set(duration, durationCounter)
    if (durationCounter > mostRecurringCounter) {
      mostRecurringCounter = durationCounter
      mostRecurringDuration = duration * 1e6
    }
  })

  const lowestFrameLength =
    mostRecurringDuration > FRAME_120FPS_AVERAGE_LENGTH
      ? FRAME_60FPS_AVERAGE_LENGTH
      : FRAME_120FPS_AVERAGE_LENGTH

  const parsedSlice = slices.map((sliceDto, index) => {
    const regex = /(\d+)$/
    const frameName = sliceDto.name.match(regex)
      ? `F${sliceDto.name.match(regex)![0]}`
      : sliceDto.name
    const slice: Slice = {
      id: sliceDto.id,
      title: frameName,
      start: sliceDto.start,
      end: sliceDto.end,
      color:
        sliceDto.end - sliceDto.start < lowestFrameLength
          ? palette.regularColor
          : palette.concernedColor,
      level: 0,
      closureId: null,
      threadId: thread.id,
      objectId: OBJECT_ID_EMPTY_VALUE,
      runnableId: OBJECT_ID_EMPTY_VALUE,
      args: [],
      children: [],
      root: null,
      rootPositionIndex: index,
      parent: null,
      isNetworkRequest: false,
      isLoggedEvent: false,
    }
    return slice
  })

  return new Thread(id, 'Frames', parsedSlice, [], 0, false, true)
}

export function parseSlicesToThreadsFromApi(
  traceData: TraceDataDto,
  palette: ChartPalette,
): { threads: Thread[]; utilityThreads: Thread[] } {
  let threads: ThreadDto[] = [...traceData.threads]
  let utilityThreads: Thread[] = []
  if (traceData.utilityThreads && isValidThread(traceData.utilityThreads.frames)) {
    const frames = parseFramesThread(traceData.utilityThreads.frames, palette.utility)

    utilityThreads = [frames]
  } else {
    const legacyFrames = threads.find(
      (thread) => thread.slices.length && LEGACY_FRAME_SLICE_TITLE.test(thread.slices[0].name),
    )
    if (legacyFrames !== undefined && isValidThread(legacyFrames)) {
      threads = threads.filter((thread) => thread.id !== legacyFrames.id)
      utilityThreads = [parseFramesThread(legacyFrames, palette.utility)]
    }
  }

  const sliceFreq: SliceFreqByTitle = calcColorFreq(threads)
  const sliceColors: SliceColorsByTitle = calcSliceColors(sliceFreq, palette.slice)

  const parsedThreads = threads.map((thread) => {
    const { slices, maxLevel } = toSlicesFromApi(
      thread.slices,
      0,
      sliceColors,
      thread.id,
      thread.isAsync,
      palette.slice,
      null,
      null,
    )

    const networkRequestsRange = []

    for (const { stackNetworkRequests } of slices) {
      if (stackNetworkRequests !== undefined && stackNetworkRequests.length) {
        for (const { start, end } of stackNetworkRequests) {
          networkRequestsRange.push({ start, end })
        }
      }
    }

    return new Thread(
      thread.id,
      thread.name,
      slices,
      networkRequestsRange,
      maxLevel,
      thread.isAsync,
    )
  })
  return { threads: parsedThreads, utilityThreads }
}

function calcSliceColors(
  sliceFreq: SliceFreqByTitle,
  slicePalette: SlicePalette,
): SliceColorsByTitle {
  const result: SliceColorsByTitle = new Map()
  const entriesArr = [...sliceFreq.entries()]
  const threshold = slicePalette.frequentColors.length
  for (let i = 0; i < threshold && i < entriesArr.length; i++) {
    const sliceTitle = entriesArr[i][0]
    const color = slicePalette.frequentColors[i % threshold]
    result.set(sliceTitle, color)
  }
  for (let i = threshold; i < entriesArr.length; i++) {
    const sliceTitle = entriesArr[i][0]
    const color = slicePalette.normalColors[(i - threshold) % slicePalette.normalColors.length]
    result.set(sliceTitle, color)
  }
  return result
}

function calcColorFreq(threads: ThreadDto[]): SliceFreqByTitle {
  const notSorted: SliceFreqByTitle = new Map()
  walkSlicesDto(threads, (slice) => {
    const count = notSorted.get(slice.name) ?? 0
    notSorted.set(slice.name, count + 1)
  })
  // noinspection UnnecessaryLocalVariableJS
  const sorted = new Map([...notSorted.entries()].sort((a, b) => b[1] - a[1]))
  return sorted
}

function toSlicesFromApi(
  apiSlices: SliceDto[],
  level: number,
  sliceColors: SliceColorsByTitle,
  threadId: number,
  isAsync: boolean,
  palette: SlicePalette,
  parent: Slice | null,
  root: Slice | null,
  parentPositionIndex?: number,
): ParseResult {
  const slices: Slice[] = []
  let maxLevel = level
  for (let positionIndex = 0; positionIndex < apiSlices.length; positionIndex++) {
    const sliceDto = apiSlices[positionIndex]
    const rootPositionIndex = parentPositionIndex ?? positionIndex
    const initialColor = sliceColors.get(sliceDto.name)!
    const color = isAsync ? getAsyncSliceColor(initialColor, level) : initialColor
    const title = sliceDto.name.trim()
    // Check slice title and if there is collected NR url in arguments by plugin.
    const isNetworkRequest =
      SLICE_NETWORK_REQUEST_TITLE_PATTERNS.test(sliceDto.name) &&
      sliceDto.arguments !== undefined &&
      sliceDto.arguments.length > 0
    const isLoggedEvent = SLICE_LOGGED_EVENT_TITLE_PATTERNS.test(sliceDto.name)
    const slice: Slice = {
      id: sliceDto.id,
      title,
      start: sliceDto.start,
      end: sliceDto.end,
      color: color,
      level: level,
      closureId: isSliceFrame(sliceDto.name) ? null : sliceDto.closureId,
      threadId: threadId,
      objectId: sliceDto.objectId ?? OBJECT_ID_EMPTY_VALUE,
      runnableId: sliceDto.runnableId ?? OBJECT_ID_EMPTY_VALUE,
      rootPositionIndex,
      args: sliceDto.arguments ?? [],
      children: [],
      extra: sliceDto.extra,
      root: root ?? null,
      parent: parent ?? null,
      isNetworkRequest,
      isLoggedEvent,
    }
    if (isNetworkRequest && root) {
      root.stackNetworkRequests = root.stackNetworkRequests ?? []
      root.stackNetworkRequests.push(slice)
    }
    if (slice.start > slice.end) {
      console.warn('Illegal slice size', slice)
    }
    if (sliceDto.children && sliceDto.children.length > 0) {
      const parsed = toSlicesFromApi(
        sliceDto.children,
        level + 1,
        sliceColors,
        threadId,
        isAsync,
        palette,
        slice,
        root ?? slice,
        rootPositionIndex,
      )
      slice.children = parsed.slices
      maxLevel = Math.max(parsed.maxLevel, maxLevel)
    }
    slices.push(slice)
  }
  return { slices, maxLevel }
}

function walkSlicesDto(
  threads: ThreadDto[],
  callback: (slice: SliceDto, thread: ThreadDto, level: number, parentId: number | null) => void,
) {
  threads.map((apiThread) => {
    const walkOverSlices = (slices: SliceDto[], level: number, parentId: number | null) => {
      for (const slice of slices) {
        callback(slice, apiThread, level, parentId)

        if (slice.children && slice.children.length > 0) {
          walkOverSlices(slice.children, level + 1, slice.id)
        }
      }
    }

    walkOverSlices(apiThread.slices, 0, null)
  })
}

const isSliceFrame = (sliceTitle: string) => /^FrameCounter.+doFrame$/.test(sliceTitle)
